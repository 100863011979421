.empty-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  >div {
    font-size: 40px;
  }
}
