.transaction-summary-card {
  background: linear-gradient(270deg, rgba(0, 46, 130, 0.15) 0%, rgba(0, 46, 130, 0) 2%);
  border-radius: 10px;
  height: 100%;
  max-height: 316px;
}


.performance-rank-card {
  padding: 24px 24px 36px;
  width: 100%;
  height: 312px;
  border-radius: 10px;
}

.performance-text-note {
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  width: 200px;
  line-height: 15px;
  margin-top: 2px;
}

.performance-btn-wrapper {
  margin-top: 17px
}


#container-scroll {
  scroll-behavior: smooth;
  overflow-x: auto;
  overflow-y: auto;
}

.scroll-icon {
  margin-top: -12em;
}

.left-control {
  position: relative;
  left: -.8em;
}

.opacity-3 {
  opacity: .3 !important;
}

.scroll-icon-right {
  position: relative;
  left: .8em
}

.scroll-icon-right {
  position: relative;
}

.performance-dashboard-wrapper {

  .flex-grow {
    flex-grow: 1;
  }

  .summary-holder {
    gap: 24px;
  }

  .mobile-performance {
    display: none;
  }

  .rating-container {
    margin-top: 60.55px;
    margin-bottom: 29.82px;
  }

  .rating-star {
    svg {
      width: 66.38px !important;
      height: 61.28px !important;
    }
  }

  .performance-text {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
    color: #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  @media screen and (max-width: 1374px) {
    .rating-star {
      svg {
        width: 63px !important;
        height: 59px !important;
      }
    }
  }

  @media screen and (max-width: 1332px) {
    .rating-star {
      svg {
        width: 60px !important;
        height: 56px !important;
      }
    }

    .performance-btn-wrapper {
      margin-top: 20px
    }
  }

  @media screen and (max-width: 1289px) {
    .rating-star {
      svg {
        width: 58px !important;
        height: 54px !important;
      }
    }

    .performance-btn-wrapper {
      margin-top: 24px
    }
  }

  @media screen and (max-width: 1269px) {
    .rating-star {
      svg {
        width: 56px !important;
        height: 50px !important;
      }
    }
  }

  @media screen and (max-width: 1257px) {
    .rating-star {
      svg {
        width: 47px !important;
        height: 47px !important;
      }
    }
  }

  @media screen and (max-width: 1116px) {
    .rating-star {
      svg {
        width: 44px !important;
        height: 44px !important;
      }
    }

    .performance-rank-card {
      padding: 24px 20px 36px;
    }
  }

  @media screen and (max-width: 1076px) {
    .rating-star {
      svg {
        width: 41px !important;
        height: 41px !important;
      }
    }
  }

  @media screen and (max-width: 1036px) {
    .rating-star {
      svg {
        width: 40px !important;
        height: 40px !important;
      }
    }

    //.performance-text{
    //  font-size: 15px;
    //  line-height: 18px;
    //}
    .performance-btn-wrapper {
      margin-top: 29px
    }

    .performance-icon-container {
      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }

  @media screen and (max-width: 995px) {
    .rating-star {
      svg {
        width: 37px !important;
        height: 37px !important;
      }
    }
  }

  @media screen and (max-width: 991px) {

    .performance-btn-wrapper {
      margin-top: 23px
    }

    .web-performance {
      display: none;
    }

    .scroll-icon {
      margin-top: -6.1em;
    }

    .left-control {
      left: -.9em
    }

    .scroll-icon-right {
      left: .9em
    }

    .mobile-performance {
      display: block;
    }

    .section-header {
      font-style: normal;
      font-weight: 700;
      font-size: 16px !important;
      line-height: 19px !important;
      color: #1A0C2F;
    }

    .transaction-summary-card {
      min-height: 199px;
      padding: 24px 20px 16px !important;

      .desc {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #1A0C2F;
        opacity: 0.6;
      }
    }

    .row-width {
      min-width: 1800px !important;
    }


    .rating-star {
      svg {
        width: 57.45px !important;
        height: 53.62px !important;
      }
    }

    .performance-text {
      font-size: 20px;
      line-height: 24px;
    }

    .plan-details-note {
      min-height: 34px;
    }

    .plan-details-note-long-string {
      min-height: 3.7em;
    }

    .performance-icon-container {
      svg {
        width: 22px !important;
        height: 22px !important;
      }
    }

    .performance-btn {
      min-width: 68px;
      max-width: 108px;
      padding-top: 10px !important;
      padding-left: 8px;
      padding-right: 8px;
      min-height: 36px !important;
      padding-bottom: 10px !important;
    }

    .performance-btn-text {
      font-weight: 700 !important;
      font-size: 13px !important;
      line-height: 16px !important;
    }
  }

  .performance-medium-text {
    display: none
  }

  .performance-extreme-mobile-text {
    display: none
  }

  @media screen and (max-width: 850px) {
    .performance-mobile-text {
      display: none
    }

    .performance-medium-text {
      display: block
    }

    .performance-extreme-mobile-text {
      display: none
    }
  }

  @media screen and (max-width: 808px) {
    .transaction-summary-card {
      //min-height: 199px!important;
      max-height: 215px;
    }
  }

  @media screen and (max-width: 768px) {
    .transaction-summary-card {
      //min-height: 199px;
      max-height: 215px;
    }
  }

  @media screen and (max-width: 550px) {
    .performance-mobile-text {
      display: none
    }

    .performance-medium-text {
      display: none
    }

    .performance-extreme-mobile-text {
      display: block
    }
  }

  @media screen and (max-width: 420px) {
    .rating-star {
      svg {
        width: 55px !important;
        height: 51px !important;
      }
    }

    .performance-text {
      font-size: 15px;
      line-height: 18px;
    }

    .performance-icon-container {
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }

  .empty-get-started-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 32px;
    //margin: 32px 0px;
  }

  .empty-get-started-rocket-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 147.52%;
    text-align: center;
    letter-spacing: 0.000909091em;
    color: #000000;
    mix-blend-mode: normal;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: -8px;
    margin-bottom: 8px;
  }

  .empty-get-started-rocket-note {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 146%;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    mix-blend-mode: normal;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 8px 0px
  }

  .performance-icon-container {
    background: rgba(255, 255, 255, 0.2);
    width: 36px;
    height: 36px;
    text-align: center;
    padding-top: 9px;
    padding-bottom: 9.72px;
    border-radius: 50%;
  }

  .performance-btn {
    min-width: 108px;
    max-width: 130px;
    min-height: 40px;
    background: #FFFFFF;
    padding-top: 10px;
    text-align: center;
    padding-bottom: 11px;
    border-radius: 10px;
    margin: 0 auto;
  }

  .performance-card-divider {
    height: 0px;
    opacity: 0.2;
    border: 1px solid #FFFFFF;
  }

  .performance-btn-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
  }

  .performance-green-card {
    background: #1A9827;

    .btn-text-color {
      color: #1A9827;
    }
  }


  .performance-blue-card {
    background: #0361F0;

    .btn-text-color {
      color: #0361F0;
    }
  }

  .performance-orange-card {
    background: #808000;

    .btn-text-color {
      color: #808000;
    }
  }

  .performance-purple-card {
    background: #9B51E0;

    .btn-text-color {
      color: #9B51E0;
    }
  }

  .performance-red-card {
    background: #444444;

    .btn-text-color {
      color: #444444;
    }
  }

  .section-header {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #1A0C2F;
  }

  .spacing-13 {
    gap: 13px;
  }

  .row-width {
    min-width: 900px;
  }

  .row-height {
    min-height: 225px;
    height: 225px;
  }

  .transaction-summary-card {
    width: 100%;
    padding: 24px;
    box-shadow: none;

    .desc {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: rgba(26, 12, 47, 0.6);
    }

    .transactions-container {
      margin-top: 16px;
      justify-content: space-between;
      // column-gap: 13px;
      // row-gap: 13px
      gap: 13px;
    }

    .transaction-item {
      width: 203px;
      height: 96px;
      background: #FAF6FD;
      border-radius: 10px;
      padding: 14px 16px;

      .title {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 11px;
        line-height: 140%;
        letter-spacing: -0.1px;
        color: rgba(0, 0, 0, 0.6);
      }

      .value {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: flex-end;
        color: #19191A;
      }

      .count {
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.8);
      }
    }
  }

  .carousel-holder {
    min-height: 422px;
    padding-top: 19px;
    background: #FFFFFF;
    box-shadow: 0px 3px 3px rgba(11, 88, 205, 0.12);
    border-radius: 16px;
    padding-bottom: 32px;

    .section-header {
      padding-left: 32px;
    }
  }

  .swiper-container {
    width: 100%;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .pricing-plan {
    width: 289px;
    height: 291px;
    background: rgba(0, 0, 0, 0.01);
    border: 1px solid #E0E6EB;
    box-sizing: border-box;
    border-radius: 10px;
  }

  .swiper-wrapper {
    .swiper-slide:first-child {
      margin-left: 32px;
    }
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    height: 32px;
    width: 32px;
    border-radius: 100px;
    color: white;
    font-weight: 900;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary-color;
    position: absolute;
  }

}