.new-user-pos-request-wrapper, .existing-user-pos-request-wrapper {
.body-wrapper {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 40px;

	// flex-flow: column;

	.card-holder {
		background: #FFFFFF;
		box-shadow: 0px 1px 2px rgba(3, 97, 240, 0.1);
		border-radius: 16px;
	}

	>div {
		width: 100%;
		max-width: 1120px;
	}
}

.pos-request-card{
	max-width: 1120px;
	min-height: 745px;
	display: flex;
	background: #FFFFFF;
	box-shadow: 0px 1px 2px rgba(3, 97, 240, 0.1);
	border-radius: 16px;
}

.desktop-stepper {
	width: 31.07%;
}

.go-back{
	font-style: normal;
	font-weight: 700;
	font-size: 15px;
	line-height: 18px;
	display: flex;
	align-items: flex-end;
	color: #1A0C2F;
	mix-blend-mode: normal;
	flex: none;
	order: 1;
	position: relative;
	top:-.15em;
	flex-grow: 0;
}

.steps-holder {
	width: 68.93%;
	background: #FAFBFC;
	padding: 47px 12.11% 61.14px;
	border-radius: 0px 16px 16px 0px;
	.title{
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 29px;
		color: #000000;
	}
	.sub-title{
		font-style: normal;
		margin-top: 4px;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #000000;
	}
}

.step-header {
	font-weight: bold;
	font-size: 24px;
	line-height: 29px;
	display: flex;
	align-items: flex-end;
	color: #000000;
  }

  .step-description {
	font-size: 14px;
	line-height: 17px;
	color: #000000;
	margin-top: 4px;
  }

  @media screen and (max-width: 480px) {
	  .body-wrapper {
		  .card-holder {
			background: none;
			box-shadow: none;
		  }
	  }

	  .steps-holder {
	background: transparent;
		>div {
			padding-top: 10px;
		}
	}
  }

  @media screen and (max-width: 700px) {
	.mobile-form {
	  display: block;
	}

	.desktop-form {
	  display: none;
	}

  }

  .content-holder {
	padding-left: 2rem;
	padding-right: 2rem;
  }

@include responsive(xs) {
		.pos-terminal-list {
			// padding-left: 20px;
			padding-right: 20px;
		}
	}
@include responsive(sm) {
		.body-wrapper {
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 0;
			margin-top: 4rem;
		}

		.pos-terminal-list {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
@include responsive(md) {
		.body-wrapper {
			margin-top: 12rem;
			padding-bottom: 8rem;
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}
}

.requested-terminals-wrapper {
	.requested-terminals-card {
		background: #FCFCFD;
		border: 0.08rem solid #F0F2F5;
		border-radius: 0.6rem;
		width: 31.2rem;
		height: 15rem;

		.terminal-background-pattern {
			width: 124px;
			height: 100%;
			border-top-left-radius: inherit;
			border-bottom-left-radius: inherit;
		}

		.requested-terminal-detail {
			width: 100%;
			height: 100%;
			border-radius: 0.6rem;
			padding-left: 30px;
			padding-right: 10px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.name {
				font-weight: 600;
				font-size: 1.6rem;
				line-height: 150%;
			}

			.serial {
				font-weight: 400;
				font-size: 1.2rem;
				line-height: 14px;
			}

			.timer-image {
				align-items: flex-end;
			}
		}
	}
}

.terminal-acceptance-modal-wrapper {
	.os-tag {
		padding: 0.4rem 0.8rem;
		min-width: 12.8rem;
		height: 2rem;
		background: #9B51E0;
		border-radius: 0.4rem;
		text-align: center;
		color: white;
	}
	.aggregator-details {
		margin-top: 2rem;
		>div {
			margin-bottom: 1.6rem;
		}
		>div:first-child {
			font-weight: 500;
			font-size: 1.6rem;
			line-height: 1.9rem;
			display: flex;
			align-items: flex-end;
			letter-spacing: 0.0115367em;
		}
	}
	.detail-row>span:first-child, .label {
		font-weight: 400;
		font-size: 1rem;
		line-height: 17px;
		display: flex;
		align-items: flex-end;
		letter-spacing: 0.0115367em;
		color: #5D7F8D;
	}

	.detail-row {
		margin-bottom: 1.8rem;
	}

	.value {
		font-weight: 500;
		font-size: 1.2rem;
		line-height: 1.8rem;
		display: flex;
		align-items: flex-end;
		letter-spacing: 0.0126316em;
	}
}