app-brm-widget {
	width: 100%;
	max-width: 333px;
}
#brm-widget {
	.brm-widget-wrapper {
		background-color: $dark-blue;
		border-radius: 12px;
		padding: 10px 12px;
		width: 100%;
		min-height: 58px;

		.img-holder {
			.avatar {
				display: inline-block;
				border-radius: 50%;
				border: 2px solid white;
				img {
					width: 34px;
					border-radius: 50%;
					height: 34px;
				}
			}
		}

		.detail-holder {
			margin-left: 10px;
			color: white;
			font-size: 13px;
			line-height: 16px;
			letter-spacing: 0.21px;
			font-weight: 600;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			flex: 1;
			.faded-text {
				color: rgba(255, 255, 255, 0.5);
				font-weight: 500;
			}
		}

		.cta {
			margin-left: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			.action {
				background: rgba(255, 255, 255, 0.15);
				border-radius: 4px;
				cursor: pointer;
				color: white;
				font-weight: 600;
				width: 74px;
				height: 29px;
				justify-content: center;
				align-items: center;
				display: flex;
				font-size: 12px;
			}
		}
	}
}

#brm-contact-dialog {
	.brm-info {
		padding-bottom: 26px;
		border-bottom: 1px solid rgba(3, 87, 238, 0.2);
		.img-holder {
			.avatar {
				display: inline-block;
				border-radius: 50%;
				img {
					width: 71px;
					height: 71px;	
					border-radius: 50%;
				}
			}
		}
		.name {
			margin-left: 11px;
			>div:first-child {
				font-weight: 800;
				font-size: 19px;
				line-height: 23px;
			}
		}
	}

	.contact-info {
		.cta-btn {
			max-width: 94px;
			height: 35px;
			background: rgba(3, 87, 238, 0.1);
			border-radius: 4px;
			font-size: 14px;
			color: $primary-color;
			padding-left: 16px;
			padding-right: 16px;
			svg {
				height: 24px;
			}
		}
		>div {
			margin-bottom: 25px;
		}
		.label {
			font-weight: 600;
			font-size: 12px;
			line-height: 15px;
			color: rgba(11, 50, 117, 0.6);
			text-transform: capitalize;
		}
		margin-top: 30px;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		border-bottom: 1px solid rgba(3, 87, 238, 0.2);
	}
}

@include responsive(xs) {
	app-brm-widget {
		width: 100%;
		max-width: 100%;
	}
}

@include responsive(md) {
	app-brm-widget {
		width: 100%;
		max-width: 333px;
	}
}