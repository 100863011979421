#business-user-dashboard {

	.widget-col {
		display: flex;
		justify-content: right;
	}

	.section-header {
		margin-bottom: 16px;
		margin-top: 24px;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
	}

	.user-quick-actions-card {
		.card {
			height: 180px;
			background: #FFFFFF;
			border-radius: 16px;

			.action {
				font-weight: 600;
				font-size: 16px;
				line-height: 19px;
			}

			.description {
				color: rgba(0, 0, 0, 0.5);
				font-weight: 400;
				font-size: 12px;
				line-height: 15px;
			}

			.quick-item {
				max-width: 170px;
				min-width: 110px;
				width: 100%;
				padding: 10px;

				&:hover {
					background: rgba(3, 87, 238, 0.04);
					border-radius: 8px;
				}
			}

			.icon-holder {
				width: 69px;
				height: 69px;
				border-radius: 50%;
				background: rgba(3, 87, 238, 0.1);
				display: flex;
				align-items: center;
				justify-content: center;
				padding-top: 6px;
				margin-right: auto;
				margin-left: auto;
				margin-bottom: 24px;
			}

			.ant-divider-vertical {
				height: 48px;
			}
		}

		.mobile-quick-action {
			width: 104px;
			height: 114px;
			border-radius: 16px;
			background: white;
			padding: 18px;

			.icon-holder {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				background: rgba(3, 87, 238, 0.1);
				display: flex;
				align-items: center;
				justify-content: center;
				padding-top: 6px;
				margin-right: auto;
				margin-left: auto;
				margin-bottom: 10px;

				svg {
					width: 24px;
				}
			}

			.action {
				font-weight: 600;
				font-size: 12px;
				line-height: 16px;
			}
		}
	}

	.business-performance-card {
		.card {
			min-height: 239px;
			background: #1EB12D;
			border-radius: 16px;
			color: white;
			font-weight: 600;
			// justify-content: space-evenly;
			padding: 25px 20px 20px 20px;
			;
			font-size: 12px;

			.top-header {
				margin-bottom: 20px;
				text-transform: capitalize;
			}

			.ant-rate {
				font-size: 48px;

				.ant-rate-star-zero {

					.ant-rate-star-first,
					.ant-rate-star-second {
						color: rgba(255, 255, 255, 0.4);
					}
				}

				.ant-rate-star-full {

					.ant-rate-star-first,
					.ant-rate-star-second {
						color: #ECAB03;
					}
				}
			}

			.ant-divider-vertical {
				height: 48px;
				border-left: 3px solid rgba(255, 255, 255, 0.3);
			}

			.growth-stat {
				.percentage-display {
					font-weight: 600;
					font-size: 41.6667px;
					line-height: 50px;
					height: 72px;
					display: flex;
					align-items: center;
				}
			}
		}

		@media only screen and (max-width: 48em) {
			.card {
				min-height: 146px;

				.ant-rate {
					font-size: 28px;
				}

				.growth-stat {
					.percentage-display {
						height: 44px;
					}
				}
			}
		}
	}

	.user-savings-card,
	.user-overdraft-card {
		.card {
			height: 239px;
			padding: 20px 0px 0px 20px;

			.value-section,
			.get-started {
				width: 60%;
				padding-bottom: 30px;

				.icon-holder {
					width: 60px;
					height: 60px;

					svg {
						width: 60px;
						height: 60px;
					}
				}

				.label {
					font-weight: 500;
					font-size: 12px;
					line-height: 15px;
					letter-spacing: 0.21px;
				}

				.amount {
					font-weight: 800;
					font-size: 24px;
					line-height: 29px;
					color: #0B3275;
				}
			}

			.get-started {
				.desc {
					font-weight: 500;
					font-size: 16px;
					line-height: 19px;
				}
			}

			.illustration-holder {
				width: 53%;
				text-align: right;
				background-image: url("/assets/images/svgs/piggy.svg");
				background-size: cover;
				background-repeat: no-repeat;
				background-position-y: 10px;
			}

		}

		@media only screen and (max-width: 48em) {
			.card {
				height: 165px;

				.value-section,
				.get-started {
					width: 70%;

					.amount {
						font-size: 20px;
					}
					.icon-holder {
						width: 34px;
						height: 34px;
						svg {
							width: 34px;
							height: 34px;
						}
					}

				}

				.illustration-holder {
					width: 53%;
					background-position-y: 10px;
				}

			}
		}
	}

	.user-overdraft-card {
		.illustration-holder {
			background-image: url("/assets/images/svgs/overdraft-rich-icon.svg") !important;
			background-position-y: 36px;
		}
	}

	.user-transactions-trend {
		.chart-wrapper {
			padding: 50px 36px;
			background: #FFFFFF;
			border-radius: 16px;
			max-height: auto;
			// overflow: auto;
		}

		div {
			transition: 0.2s ease all;
		}

		canvas {
			max-height: 300px !important;
		}

		.top-gradient {
			height: 40px;
			background: linear-gradient(360deg, rgba(3, 87, 238, 0.08) 0%, rgba(3, 87, 238, 0) 50%);
			transform: matrix(1, 0, 0, -1, 0, 0);
			position: absolute;
			width: calc(100% + 72px);
			top: -20px;
			left: -36px;
		}


		.legends {
			display: flex;
			align-items: center;
			margin-top: 30px;

			>div {
				margin-right: 32px;
				display: flex;
				align-items: center;

				.indicator {
					margin-right: 8px;
					width: 14px;
					display: inline-block;
					height: 14px;
					border-radius: 3px;

					&.withdrawal {
						background: #0361F0;
					}

					&.deposit,
					&.transfer {
						background: #1EB12D;
					}
				}
			}
		}

		@media only screen and (max-width: 48em) {
			.chart-wrapper {
				padding: 30px 10px;
				background: #FFFFFF;
				box-shadow: 0px 3px 3px rgba(11, 88, 205, 0.12);
				width: 100%;
			}

			.top-gradient {
				width: calc(100% + 20px);
				left: -10px;
			}
		}

	}

	@include responsive(xs) {
		.widget-col {
			justify-content: left;
			padding-top: 16px !important;
		}
	}

	@include responsive(md) {
		.widget-col {
			justify-content: right;
			padding-top: 0px !important;
		}
	}

}