.card {
  border: none;
}

.mobile-top-curved-card {
	height: 100%;
	width: 100%;
	border-top-left-radius: 1.6rem;
	border-top-right-radius: 1.6rem;
	background: white;
	padding-top: 3rem;
	padding-left: 2rem;
	padding-right: 2rem;
	padding-bottom: 6rem;
}