@import "src/assets/styles/utils/text-util";
@import "src/assets/styles/utils/variables";

.transaction-page-header {
	.ant-page-header-heading-title {
		@include common-text-style(700, 13px, 16px);
		color: $dark-purple;
	}
}

.record-day-header {
	@extend .transaction-page-header;
}

.unreconciled-transaction-detail-header,.reconciled-transaction-detail-header,.quarantined-transaction-detail-header {
	@extend .transaction-page-header;
}
