.confirm-subscription-backdrop {
	background: #c4c8cc;
	opacity: 1;
}

.unreconciled-transaction-detail, .reconciled-transaction-detail, .quarantined-transaction-detail {
	@extend .confirm-subscription-backdrop;

	&-panel {
		border-radius: 16px;
		background: #FFF;
		padding: 3px;
	}
}

.reconcile-transaction {
	@extend .unreconciled-transaction-detail;
}
