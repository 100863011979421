@import "../utils/module";

.trigger:hover {
	color: $primary-color;
}

.header-trigger {
	height: 64px;
	//   padding: 20px 24px;
	padding-right: 24px;
	padding-left: 24px;
	font-size: 20px;
	cursor: pointer;
	transition: all .1s, padding 0s;
}

.header-alignment {
	margin-top: .5em;
	float: right;
}

.drop-down {
	position: relative;
	top: .3em
}

.language {
	color: #063A4F;
	text-align: right;
	font-size: 16px;
	font-weight: 500;
}

.language-lift {
	margin-top: -.2em;
}

.language:hover {
	color: #063A4F;
}


.app-header {
	//padding-right: 24px;
	padding-left: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	height: 70px;
	padding: 0;
	background: #fff;
	border-bottom: 1px solid rgba(3, 87, 238, 0.1);
}

.app-header-contents {
	width: 100%;
	max-width: 1600px;
	display: flex;
	align-items: center;
	position: relative;
	justify-content: space-between;
	overflow: hidden;
}

@media screen and (min-width: 768px) {
	.app-header-contents {
		padding-right: 36px;
	}
}

//Huge screen
@media screen and (min-width: 1638px) {
	.app-header-contents {
		padding-right: 48px;
	}
}

// cinema
@media screen and (min-width: 1669px) {
	.app-header-contents {
		padding-right: 65px;
	}
}

@media (max-width: 786px) {
	.hidden-xs.header-trigger.header-dropdown {
		display: none !important;
	}
}

@media (max-width: 795px) {
	.hidden-xs.header-trigger.header-dropdown {
		display: none !important;
	}
}

@media (max-width: 795px) {
	.visible-xs.header-trigger.header-dropdown {
		display: block !important;
	}
}
