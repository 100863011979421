.business-overview-container {
  .business-summary {

    app-business-account-card,
    app-business-info-card {
      width: 100%;

      >.card {
        box-shadow: 0px 3px 3px rgba(11, 88, 205, 0.12);
      }

      >div {
        width: 100%;
        min-height: 248px;
        padding: 32px;
      }
    }

    .account-breakdown {
      .top {
        .label {
          font-size: 12px;
          font-weight: 500;
          line-height: 15px;
          letter-spacing: 0.21px;
        }
      }

      .bottom {
        .card {
          max-width: 240px;
          flex-grow: 1;
          flex-shrink: 0;
          height: 67px;
          background: #E6EFFD;
          border-radius: 16px;
          padding: 16px;

          .label {
            color: #7C7984;
            font-size: 11px;
            line-height: 13px;
          }
        }

        .copy-section {
          width: 82px;
          height: 33px;
          background: #0361F0;
          border-radius: 8px;
          color: white;
          justify-content: space-around;
          font-weight: 500;
          padding: 6px;
        }
      }

      .value {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.0126316em;
        margin-top: 5px;
      }
    }

    .amount,
    .naira {
      font-size: 36px;
      font-weight: 600;
      color: #063A4F;
      line-height: 44px;

      letter-spacing: 1px;
    }

    .info {}
  }
}
