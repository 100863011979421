@import "src/assets/styles/utils/text-util";
@import "src/assets/styles/utils/variables";

[appFloatingLabelSelect] {
	position: relative;

	&.ng-invalid:not(.ng-pristine) {
		span.floating-label {
			color: #FF4D4F;
		}
	}

	nz-select-top-control {
		@extend .custom-height;
	}

	nz-select-item {
		@extend .custom-padding;
	}

	span.floating-label {
		@include common-text-style(400, 13px, 20px);
		position: absolute;
		top: 50%;
		translate: 0 -50%;
		left: 12px;
		color: transparentize(black, .6);
		transition: all .2s ease-out;
	}

	&.floated {
		span.floating-label {
			@include common-text-style(400, 10px, 14px);
			top: 10px;
			translate: none;
		}
	}

	&.is-date-picker {
		@extend .custom-height;

		input[ng-reflect-model]:not(input[ng-reflect-model='']) {
			@extend .custom-padding;
		}
	}
}

.custom-height {
	height: 56px !important;
}

.custom-padding {
	padding-top: 15px !important;
}
