.thick-progress-spinner {
	svg:first-of-type {
		overflow: visible;
		width: 40px;
		height: 40px;

		g:first-of-type {
			g:first-of-type {
				stroke-width: 4;
			}
		}
	}
}
