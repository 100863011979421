@import "../utils/variables";

.options-container {
	display: flex;
	flex-flow: column nowrap;

	label {
		display: flex;
		justify-content: space-between;

		.ant-radio {
			order: 5;
		}
	}

	@extend .large-radio;
}

.bookkeeping-settings-options {
	@extend .options-container;

	& ~ & {
		margin-top: 25px;
	}

	label {
		justify-content: flex-start;
		align-items: center;

		.ant-radio {
			order: -1;
			top: 0;
		}
	}
}

.large-radio {
	.ant-radio {
		top: unset;
	}
	
	.ant-radio-checked .ant-radio-inner {
		border-color: $primary-color !important;
	}

	.ant-radio-checked .ant-radio-inner:after {
		background-color: $primary-color;
	}

	.ant-radio:hover .ant-radio-inner {
		border-color: $primary-color;
	}

	.ant-radio-inner {
		width: 24px;
		height: 24px;

		&:after {
			width: 16px;
			height: 16px;
		}
	}
}
