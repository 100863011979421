@import "src/assets/styles/utils/variables";

.issues-checkbox {
	&:not(.is-large) {
		.ant-checkbox-inner {
			border: 0.888889px solid $issues-checkbox-border;
			background: transparentize(#FFF, .9);
		}
	}

	.ant-checkbox-wrapper .ant-checkbox::after, .ant-checkbox::after {
		border-radius: 100%;
	}

	.ant-checkbox-inner {
		border-radius: 100%;
		width: 15.11px;
		height: 15.11px;
	}
}
