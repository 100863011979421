$primary-color: #0361F0;
$accent-color: #FAB417;
$faded-grey: #BFC5D2;
$white: #FCFCFD;
$grey: #F0F2F5;
$primary-text-color: #000000;
$primary-desc-color: rgba(0, 0, 0, 0.5);
$primary-text-title-color: #092256;
$primary-light-slate-grey: #5d7f8d;
$light-blue: #9ba6b9;
$elephant: #063a4f;
$green: #1EB12D;
$dark-purple: #1A0C2F;
$background-color-green: rgba(30, 177, 45, 0.1);
$orange: #F08922;
$background-color-orange: rgba(240, 137, 34, 0.3);
$red: #E94444;
$background-color-red: rgba(233, 68, 68, 0.1);
$background-color-grey: rgba(0, 0, 0, 0.05);
$background-color-grey1: rgba(0, 0, 0, 0.200);
$background-color-light-blue: rgba(11, 50, 117, 0.7);
$background-color-light-red: rgba(233, 68, 68, 0.2);
$background-color-blue-btn: rgba(3, 87, 238, 0.1);
$background-color-lighter-blue-btn: rgba(3, 87, 238, 0.1);
$background-light: #FAFCFF;
$purple: #9B51E0;
$sky-blue: #1B97DB;
$pink: #FF61AA;
$dark-blue: #0A3070;
$dark-blue-text: #0B3275;
$dark-grey: #0B327533;
$table-header-color: #7C7984;
$card-border-color: rgba(11, 50, 117, 0.1);
$issues-checkbox-border: #A6B6CE;
$deep-grey: #9DA1AB;
$background-blue-shade: #0357EE;
$almost-black: #1E2E3F;
$grey-faded: #B4CBD5;
$voilet-color: #612D93;
$white-color: #FFFFFF;
$orange-color: #ECAB03;
$bg-blue-secondary : #0357EE0D;
