.faded-grey {
  color: $faded-grey;
}

.orange-text {
  color: #F4803C;
}

.primary-color {
  color: $primary-color;
}