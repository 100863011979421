@import "../utils/module";

.menu-holder {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 240px);
  padding-top: 16px;
  /*  padding-bottom: 20px; */
  border-right: 1px solid rgba(3, 87, 238, 0.05);

  .ant-menu-inline {
    border: none;
    box-shadow: 1px 0px 1px rgba(212, 235, 245, 0.09);
  }

  .grouped-menu-list {

    &.label {
      margin-top: 10px !important;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.0126316em;
      text-transform: uppercase;
      color: rgba(11, 50, 117, 0.7);
      pointer-events: none;
    }
  }

  .ant-menu-item-selected {
    background: rgba(3, 87, 238, 0.1) !important;
    border-right: none;


    .custom-child-menu {

      height: 55px !important;
      border-bottom: 1px solid rgba(3, 87, 238, 0.1);
    }

    svg {

      rect,
      path {
        fill: $primary-color;
        opacity: 1;
      }
    }

    a {
      color: #1A0C2F;
    }
  }
}

.menu-sidebar {
  position: relative;
  z-index: 10;
  min-height: 100vh;
  //   box-shadow: 2px 0 6px rgba(0, 21, 41, .35);
  background: white;
  font-size: 13px;
}

.ant-layout {
  height: auto;
  overflow: hidden;
}

.ant-menu-inline > .ant-menu-item {
  height: 44px;
  display: flex !important;
  align-items: center;
  margin: 0 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.0126316em;

  a {

    color: #1A0C2F !important;
  }


  svg {

    rect,
    path {
      //   fill: #62626F;
    }
  }
}

.ant-menu-submenu {
  border-bottom: 1px solid rgba(3, 87, 238, 0.1);

  li {
    margin: 0 !important;
  }


  svg {

    rect,
    path {
      fill: #62626F;
    }
  }
}

.ant-menu-submenu-title {

  height: 55px !important;
  margin: 0 !important;
  padding-right: 0px !important;

  .ng-star-inserted {

    position: relative;
    top: .56em;
  }

  .ant-menu-submenu-arrow.ng-star-inserted {

    float: right;
    margin-top: 1.7em;
  }
}


/*li {
  
  .ant-menu-item-selected {
    
    ::before {
      //   content: '';
      //   border-left: 2px solid $primary-color;
    }
    
  }
}
*/

.ant-menu-inline .ant-menu-item::after,
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after {
  border-right: none;
}

.ant-menu-inline .ant-menu-item::before,
.ant-menu-vertical .ant-menu-item::before,
.ant-menu-vertical-left .ant-menu-item::before,
.ant-menu-vertical-right .ant-menu-item::before {
  border-left: 3px solid $accent-color;
}

.ant-menu-item a {
  color: #62626F;
}

.sidebar-logo {
  position: relative;
  height: 70px;
  //   padding-left: 24px;
  text-align: center;
  overflow: hidden;
  line-height: 64px;
  background: white;
  transition: all .3s;
}

.sidebar-logo img {
  display: inline-block;
  height: 32px;
  width: 32px;
  vertical-align: middle;
}

.sidebar-logo h1 {
  display: inline-block;
  margin: 0 0 0 20px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  font-family: rubik, Helvetica Neue, Arial, Helvetica, sans-serif;
  vertical-align: middle;
}

.user-profile {
  height: 101px;
  padding-top: 11px;
  padding-bottom: 15px;
  padding-left: 26px;
  background: rgba(234, 238, 249, 0.35);
  border-right: 1px solid rgba(3, 87, 238, 0.05);

  .ant-row {
    flex: 1;
    align-items: center;
  }

  .orb {
    width: 30.43px;
    height: 31px;
    background: rgba(3, 97, 240, 0.1);
    display: block;
    border-radius: 50%;
  }

  //   align-items: center;
  //   flex-direction: row;

  .user-info {

    .user-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 19.36px;
      margin-top: 4px;
    }

    .user-email {
      font-weight: 400;
      size: 12px;
      line-height: 14.52px;
      margin-top: 4px;
    }

    .user-role {
      padding: 4px 8px 4px 8px;
      background-color: rgba(3, 87, 238, 0.1);
      border-radius: 4px;
      color: #0361F0;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      margin-top: 8px;
    }
  }

  .user-avatar {
    align-items: center;
    display: flex;

    svg {

      width: 60px;
      height: 60px;
    }
  }
}

.adjust-sidenav-drop-down-icon {

  position: relative !important;
  top: .4em !important;
}

.sidebar-menu-icon {
  width: 38px;
}
