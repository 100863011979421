$spaceDirection: (
    'k-pt': 'padding-top',
    'k-pb': 'padding-bottom',
    'k-pl': 'padding-left',
    'k-pr': 'padding-right',
    'k-p': 'padding',
    'k-mt': 'margin-top',
    'k-mb': 'margin-bottom',
    'k-ml': 'margin-left',
    'k-mr': 'margin-right',
    'k-m': 'margin',
);

$sizes: (
    1: 1px,
    2: 2px,
    4: 4px,
    6: 6px,
    8: 8px,
    10: 10px,
    12: 12px,
    14: 14px,
    16: 16px,
    18: 18px,
    20: 20px,
    24: 24px,
    28: 28px,
    32: 32px,
    36: 36px,
    40: 40px,
    44: 44px,
    48: 48px,
    56: 56px,
    64: 64px
);

$media: (
    xs: 375px,
    sm: 480px,
    md: 768px,
    lg: 1024px,
    xl: 1280px,
);

@mixin spacing() {
	@each $class, $direction in $spaceDirection {
        @each $size, $value in $sizes {
            .#{$class}-#{$size} {
                #{$direction}: $value
            }
            @each $mediaProp, $mediaValue in $media {
				@if $mediaValue {
					@media screen and (min-width: $mediaValue) {
						.#{$mediaProp}\:#{$class}-#{$size} {
							#{$direction}: $value !important
						}
					}
				}
			}
		}
	}
}
