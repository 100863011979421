.custom-moniepoint-input input {
	height: 56px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-affix-wrapper,
.ant-picker {
	//   height: 56px!important;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	box-sizing: border-box;
	align-items: center;
}

.custom-moniepoint-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-affix-wrapper,
.ant-picker {
	height: 50px !important;
}

.ant-input-affix-wrapper {
	padding: 0 11px;
}

.ant-form-item {
	margin: 0 0 24px;
}

.ant-input {
	background-color: #ffffff;
	height: 46px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	box-sizing: border-box;
	border-radius: 4px !important;
}

.moniepoint-input .ant-input {
	padding-top: 12px !important;
}

.ant-input-affix-wrapper {
	height: 56px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
	//background-color: rgb(255, 255, 255) !important;
}

.anticon-down {
	color: $primary-color;
}

::-webkit-input-placeholder,
:-ms-input-placeholder,
::placeholder {
	font-style: normal;
	font-weight: 400 !important;
	font-size: 13px !important;
	line-height: 16px !important;
	letter-spacing: 0.000909091em;
	color: rgba(0, 0, 0, 0.4) !important;
	mix-blend-mode: normal;
	flex: none;
	order: 1;
	flex-grow: 1;
	margin: 2px 2px !important;
}

#upload-photo,
.upload-file {
	display: none;
}

.custom-file-upload {
	height: 60px;
	width: 100%;
	border: 0.75px solid #e1ecfd;
	border-radius: 5px;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding-left: 20px;
	padding-right: 20px;
	gap: 14px;
	font-size: 12px;
}

.custom-floating-input {
	position: relative;
}

.custom-floating-input > .custom-input {
	height: 56px;
}

.custom-floating-input > .custom-select {
	//height: 56px;
}

.custom-floating-input > .custom-input,
.custom-floating-input > .custom-select {
	line-height: 1.25;
	background: #ffffff;
	border: 0.7px solid rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	border-radius: 4px;
	width: 100%;
}

.custom-floating-input > label {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	padding: 14px 10.5px;
	pointer-events: none;
	border: 1px solid transparent;
	transform-origin: 0 0;
	transition: top 0.1s ease-in-out, transform 0.1s ease-in-out;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: 0.013px;
	color: rgba(0, 0, 0, 0.4);
	margin: 2px 0;
}

@media (prefers-reduced-motion: reduce) {
	.custom-floating-input > label {
		transition: none;
	}
}

.custom-floating-input > .custom-input {
	padding: 4px 10.5px;
}

.custom-floating-input > .custom-input::-moz-placeholder {
	color: transparent;
}

.custom-floating-input > .custom-input::placeholder {
	color: transparent;
}

.custom-floating-input > .custom-input:not(:-moz-placeholder-shown) {
	padding-top: 22.75px;
	padding-bottom: 8.75px;
}

.custom-floating-input > .custom-input:focus,
.custom-floating-input > .custom-input:not(:placeholder-shown) {
	padding-top: 22.75px;
	padding-bottom: 0.625rem;
}

.custom-floating-input > .custom-select:focus,
.custom-floating-input > .custom-select:not(:placeholder-shown) {
	padding-top: 22.75px;
	padding-bottom: 8.75px;
}

.custom-floating-input > .custom-input:-webkit-autofill {
	padding-top: 22.75px;
	padding-bottom: 8.75px;
}

.custom-floating-input > .custom-select {
	padding-top: 22.75px;
	padding-bottom: 8.75px;
}

.custom-floating-input > .custom-select .ant-select-arrow i {
	color: #000000 !important;
}

.custom-floating-input > .custom-input:not(:-moz-placeholder-shown) ~ label {
	color: rgba(0, 0, 0, 0.4);
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.custom-floating-input > .custom-input:focus ~ label,
.custom-floating-input > .custom-input:not(:placeholder-shown) ~ label {
	color: rgba(0, 0, 0, 0.4);
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.custom-floating-input > .custom-input:-webkit-autofill ~ label {
	color: rgba(0, 0, 0, 0.4);
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.custom-floating-input .ant-select-selector {
	margin-top: -1.65em;
	border: none !important;
}

.custom-date-input {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.custom-date-input .ant-picker-clear i {
	position: relative;
	top: 0 !important;
}

.custom-date-input .ant-picker-suffix {
	position: relative;
	top: 0 !important;
}

.custom-floating-input .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	box-shadow: none !important;
	border: none !important;
}

.custom-floating-input .ant-select-selection-search-input,
.ant-select-selection-search {
	height: 56px !important;
}

.custom-floating-input
	.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
	.ant-select-selector:hover {
	box-shadow: none !important;
	border: none !important;
}

.custom-floating-input .ant-select {
	height: 56px;
}

.custom-floating-input .ant-select-selection-item {
	margin-top: 2.12em !important;
}

.custom-floating-input .ant-select-focused {
	border: 1px solid #0361f0 !important;
}

.custom-floating-input input:focus {
	border: 1px solid #0361f0 !important;
	box-shadow: none !important;
}

.custom-floating-input .custom-select input:focus {
	border: none !important;
	box-shadow: none !important;
}

.select-float-label {
	position: absolute !important;
	opacity: 0.6 !important;
	font-size: 12px !important;
	top: -0.8em !important;
	transition: top 2s;
}

.custom-input-error {
	border: 1px solid #e94444 !important;
}

.select-label {
	@apply absolute;
}

.kamona-input-form-container {
	@apply relative items-center w-full;

	.kamona-input-suffix {
		@apply absolute w-6 ml-auto mr-4 z-10 h-full flex items-center top-0 right-0;
	}

	.mobile-prefix-container-valid {
		border: 1px solid $primary-color !important;
	}

	.mobile-prefix-container-invalid {
		border: 1px solid #e94444 !important;
	}

	.mobile-prefix-container {
		padding: 17px 5px 15px;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		border: 0.7px solid rgba(11, 50, 117, 0.3);
		border-right: 0 !important;

		.svg {
			width: 1.143rem;
			height: 0.857rem;
		}

		.mobile-prefix {
			padding-left: 8px;
			font-style: normal;
			font-weight: 400;
			font-size: 0.929rem;
			line-height: 1.143rem;
			position: relative;
			letter-spacing: 0.000909091em;
			color: rgba(0, 0, 0, 0.7);
		}
	}

	.input-form {
		display: flex;
		flex-direction: column;
		//gap: 2rem;
		width: 100%;
	}

	.date-container {
		position: relative;
	}

	.error-message {
		margin-top: 8px;
		font-style: normal;
		font-weight: 400;
		font-size: 10px;
		line-height: 16px;
		color: #e94444;
	}

	.custom-input-wrapper {
		position: relative;
		border-radius: 4px 4px 0 0;
		height: 56px;
		transition: background-color 0.2s;
	}

	label {
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 16px;
		transform-origin: left top;
		user-select: none;
		transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1), top 0.2s;
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
		align-items: center;
		letter-spacing: 0.000909091em;
		color: rgba(0, 0, 0, 0.29);
		mix-blend-mode: normal;
	}

	input {
		width: 100%;
		box-sizing: border-box;
		transition: border 0.1s;
		padding: 20px 16px 6px;
		background: #ffffff;
		border: 0.7px solid rgba(0, 0, 0, 0.2);
		border-radius: 4px;
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
		letter-spacing: 0.000909091em;
		color: rgba(0, 0, 0, 0.29);
		mix-blend-mode: normal;
		height: 56px;
	}

	input:focus {
		outline: none;
		border: 1px solid $primary-color;
	}

	::ng-deep .ant-picker,
	.ant-picker-input {
		width: 100%;
		box-sizing: border-box;
		transition: border 0.1s;
		padding: 20px 16px 6px;
		background: #ffffff;
		border: 0.7px solid rgba(0, 0, 0, 0.2);
		border-radius: 4px;
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
		letter-spacing: 0.000909091em;
		color: rgba(0, 0, 0, 0.29);
		mix-blend-mode: normal;
		height: 56px !important;
	}

	::ng-deep .ant-picker-input > input {
		position: relative;
		top: 0.2em;
	}

	::ng-deep .ant-picker-input > input:hover {
		outline: none;
		border: none;
	}

	::ng-deep .ant-picker-focused,
	.ant-picker:hover {
		outline: none;
		border: 1px solid $primary-color;
	}

	::ng-deep .ant-picker-input {
		position: relative;
		top: -0.32em;
	}

	::ng-deep .ant-picker + label {
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
		letter-spacing: 0.000909091em;
		color: rgba(0, 0, 0, 0.29);
		mix-blend-mode: normal;
	}

	::ng-deep .ant-picker-focused + label,
	.ant-picker:hover + label {
		color: $primary-color;
		transform: translateY(-100%) scale(0.75);
	}

	.date-valid {
		::ng-deep .ant-picker + label {
			color: rgba(0, 0, 0, 0.29);
			transform: translateY(-100%) scale(0.75);
		}
	}

	.in-date-valid {
		::ng-deep .ant-picker + label {
			color: #e94444;
			transform: translateY(-100%) scale(0.75);
		}

		::ng-deep .ant-picker {
			border: 1px solid #e94444;
		}
	}

	input:focus + label {
		color: $primary-color;
	}

	input:focus + .mobile-prefix-container {
		border: 1px solid $primary-color;
	}

	input:focus + label,
	input.valid + label {
		transform: translateY(-100%) scale(0.75);
		margin-top: 2px;
	}

	input.valid {
		color: rgba(0, 0, 0, 1);
	}

	input.in-valid:focus + label,
	input.in-valid + label {
		transform: translateY(-100%) scale(0.75);
		margin-top: 2px;
		color: #e94444;
	}

	input.in-valid {
		border: 1px solid #e94444;
	}
}

.money-move-form {
	div.custom-amount-container {
		border-radius: 4px !important;
		border-color: transparentize(#000, 0.7) !important;
	}

	nz-input-group.custom-amount.ant-input-group-wrapper {
		border-radius: 4px !important;
	}
}

.reconcile-transaction-form {
	@extend .money-move-form;
}

.disable-input {
	input,
	nz-select,
	nz-select-top-control,
	nz-select-arrow i {
		pointer-events: none !important;
		color: #aaa !important;
		background: #f5f5f5 !important;
	}
}

.status-select .status-select-dropdown .ant-select-selector {
  height: 46px;
}