//Rubik font
@font-face {
  font-family: 'rubik';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/rubik/Rubik-Light.ttf');
}

@font-face {
  font-family: 'rubik';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/rubik/Rubik-LightItalic.ttf');
}

@font-face {
  font-family: 'rubik';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/rubik/Rubik-Regular.ttf');
}

@font-face {
  font-family: 'rubik';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/rubik/Rubik-Italic.ttf');
}

@font-face {
  font-family: 'rubik';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/rubik/Rubik-Medium.ttf');
}

@font-face {
  font-family: 'rubik';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/rubik/Rubik-MediumItalic.ttf');
}

@font-face {
  font-family: 'rubik';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/rubik/Rubik-SemiBold.ttf');
}

@font-face {
  font-family: 'rubik';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/rubik/Rubik-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'rubik';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/rubik/Rubik-Bold.ttf');
}

@font-face {
  font-family: 'rubik';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/rubik/Rubik-BoldItalic.ttf');
}

@font-face {
  font-family: 'rubik';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/rubik/Rubik-ExtraBold.ttf');
}

@font-face {
  font-family: 'rubik';
  font-style: italic;
  font-weight: 800;
  src: url('../fonts/rubik/Rubik-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'rubik';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/rubik/Rubik-Black.ttf');
}

@font-face {
  font-family: 'rubik';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/rubik/Rubik-BlackItalic.ttf');
}


// Nunito Sans

@font-face {
  font-family: 'nunito';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/nunito_Sans/NunitoSans-ExtraLight.ttf');
}

@font-face {
  font-family: 'nunito';
  font-style: italic;
  font-weight: 200;
  src: url('../fonts/nunito_Sans/NunitoSans-ExtraLightItalic.ttf');
}

@font-face {
  font-family: 'nunito';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/nunito_Sans/NunitoSans-Light.ttf');
}

@font-face {
  font-family: 'nunito';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/nunito_Sans/NunitoSans-LightItalic.ttf');
}

@font-face {
  font-family: 'nunito';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/nunito_Sans/NunitoSans-Light.ttf');
}

@font-face {
  font-family: 'nunito';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/nunito_Sans/NunitoSans-LightItalic.ttf');
}

@font-face {
  font-family: 'nunito';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/nunito_Sans/NunitoSans-Regular.ttf');
}

@font-face {
  font-family: 'nunito';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/nunito_Sans/NunitoSans-Italic.ttf');
}

@font-face {
  font-family: 'nunito';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/nunito_Sans/NunitoSans-SemiBold.ttf');
}

@font-face {
  font-family: 'nunito';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/nunito_Sans/NunitoSans-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'nunito';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/nunito_Sans/NunitoSans-Bold.ttf');
}

@font-face {
  font-family: 'nunito';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/nunito_Sans/NunitoSans-BoldItalic.ttf');
}

@font-face {
  font-family: 'nunito';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/nunito_Sans/NunitoSans-ExtraBold.ttf');
}

@font-face {
  font-family: 'nunito';
  font-style: italic;
  font-weight: 800;
  src: url('../fonts/nunito_Sans/NunitoSans-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'nunito';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/nunito_Sans/NunitoSans-Black.ttf');
}

@font-face {
  font-family: 'nunito';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/nunito_Sans/NunitoSans-BlackItalic.ttf');
}

// Inter Sans

@font-face {
  font-family: 'inter';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/Inter/Inter-Thin.ttf');
}

@font-face {
  font-family: 'inter';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/Inter/Inter-ExtraLight.ttf');
}

@font-face {
  font-family: 'inter';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Inter/Inter-Light.ttf');
}

@font-face {
  font-family: 'inter';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Inter/Inter-Regular.ttf');
}

@font-face {
  font-family: 'inter';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Inter/Inter-Medium.ttf');
}

@font-face {
  font-family: 'inter';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Inter/Inter-SemiBold.ttf');
}

@font-face {
  font-family: 'inter';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Inter/Inter-Bold.ttf');
}

@font-face {
  font-family: 'inter';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/Inter/Inter-ExtraBold.ttf');
}

@font-face {
  font-family: 'inter';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Inter/Inter-Black.ttf');
}

@font-face {
  font-family: 'Imperial Script';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/imperial/ImperialScript-Regular.ttf');
}

@font-face {
	font-family: 'Founders Grotesk';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/founders_grotesk/FoundersGrotesk-Regular.otf');
  }

  @font-face {
	font-family: 'Founders Grotesk';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/founders_grotesk/FoundersGrotesk-Semibold.otf');
  }

  @font-face {
	font-family: 'Founders Grotesk';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/founders_grotesk/FoundersGrotesk-Bold.otf');
  }


// General Sans
@font-face {
  font-family: 'General';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/general_sans/GeneralSans-Semibold.woff');
}

$font-rubik: 'rubik',
sans-serif;
$font-nunito: 'nunito',
sans-serif;
$font-inter: 'inter',
sans-serif;
$font-founders: 'Founders Grotesk',
sans-serif;
