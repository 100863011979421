.saving-plans-description {
  //   background: rgba(3, 97, 240, 0.05);
  border-radius: 8px;
  //   padding: 16px 16px 0;

  .ant-collapse {
    background: transparent;
    border-radius: 8px;
    font-size: 13px;
  }

  .ant-collapse-header {
    font-weight: 500;
  }
}


.outlet-table {
  margin-top: 10px;
  padding-top: 24px;
  padding-left: 24px;
  padding-bottom: 16px;
  width: 100%;
}

.outlet-name {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 19px;
  letter-spacing: 0.0126316em;
  mix-blend-mode: normal;
}

.outlet-text {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.0126316em;
  color: #063A4F;
  mix-blend-mode: normal;
}

.agent-trans-text {
  font-style: normal;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.0126316em;
  color: #063A4F;
  line-height: 15px;
  mix-blend-mode: normal;
}

.agent-trans-text-space{
  margin-top:5px!important;
}

.outlet-text-name {
  margin-left: 6.7px;
}

.outlet-content-spacing {
  margin-top: 15px;
}

.outlet-pagination {
  margin-top: 22px;
  text-align: center;
}

.outlet-margin-adjustment {
  margin-top: 10px;
}

.outlet-table-title {
  font-size: 18px;
  line-height: 21px;
  font-weight: 500
}

.mobile-outlet-icon {
  text-align: right
}

.mobile-outlet-icon i {
  font-size: 40px;
  color: #0361F0;
  cursor: pointer;
}
