.login-form {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.login-form-margin {
  margin-bottom: 16px;
}

.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
}

.center-page {
  min-height: 100vh;
  overflow-y: auto;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;

  .flex-login {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
    padding-left: 20px;
    flex-flow: column;

    // padding-top: 140px;
    >h1 {
      font-weight: bold;
      font-size: 32px;
      line-height: 39px;
    }

    h2 {
      font-weight: bold;
    }

  }

  .plain-bg {
    background: white !important;
  }

  .login-holder {
    // padding: 80px 50px;
    border: 1px solid #e1e4e8;
    border-radius: 6px;
    background: white;
    // height: 460px;
    width: 480px;
    display: flex;
    justify-content: center;
    // align-items: center;
    flex-direction: column;
    padding: 40px;
    color: #063A4F;

    .login-logo {
      display: flex;
      justify-content: center;
      position: relative;
      top: -30px;

      img {
        width: 60px;
        filter: drop-shadow(0px 15.601px 28.8019px rgba(4, 129, 245, 0.26));
      }
    }

    p {
      font-size: 13px;
    }
  }
}


.fancy-pattern {
  background-image: url("/assets/images/login-top-pattern.svg");
  height: 16px;
  background-color: blue;
}


.login-error-alert {
  // top: -40px;
  //position:absolute;
  //top:10px;
  //right: 0;
  //left: 0;
  margin-top: 40px;
  //z-index: 2500;
  //   min-width: 400px;
  max-width: 950px;
  font-family: $font-rubik;
  margin-right: auto;
  margin-left: auto;

  .ant-alert {
    min-height: 68px;
    height: auto;
  }
}

.hero-section {
  height: 100%;

  .hero-bg {
    height: 100%;

    .plain-bg {
      background: white !important;
    }
  }

  .hero-image {
    background: url("/assets/images/moniepoint-hero-image.png");
    background-repeat: no-repeat;
    background-position: left bottom;
    height: 100%;
    width: 100%;
    background-size: 100% auto;
    z-index: 1;
    position: absolute;
  }

  .blue-pattern-bg {
    background: #0361F0 url("/assets/images/svgs/hero-bg-pattern.svg");
    padding: 50px 0 0 70px;
  }
}

.blue-pattern-bg {
  background: #0361F0 url("/assets/images/svgs/hero-bg-pattern.svg");
}

.loading-text{
	color:#FFFFFF;
	position:relative;
	top:-.3em;
	font-weight:700;
	font-style: normal;
	font-size: 1em;
	line-height: 1.2857142857142858em;
}

@media (max-width: 48em) {
  .login-error-alert {
    max-width: 400px;
  }

  .center-page {
    flex-flow: column;

    .login-logo {
	  text-align: center;
      margin-bottom: 40px;

      img {
        width: 61px;
      }
    }

    .login-holder {
      width: 380px;
    }
  }
}

@media (max-width: 280px) {
  .login-holder {
    max-width: 260px;
    width: 100%;
  }
}

@media (min-width: 280px) and (max-width: 370px) {

  .center-page {

    .login-holder {
      width: 100%;
      max-width: 340px;
    }
  }
}
