button,
.ant-btn {
	border-radius: 4px;
	height: 48px;
	font-weight: 500;
	font-size: 12px;
	cursor: pointer;
}

.desktop-button-wrapper {
	button,
	.ant-btn {
		height: 56px;
		border-radius: 4px !important;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
	}
}

.kamona-button-session {
	button,
	.ant-btn {
		height: 56px;
		border-radius: 8px !important;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
	}
}


.kamona-button-session-v2 {
	button,
	.ant-btn {
		height: 48px!important;
		border-radius: 8px !important;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
	}
}

.kamona-desktop-button-wrapper {
	button,
	.ant-btn {
		height: 56px;
		border-radius: 4px !important;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
	}
}

.kamona-desktop-button-wrapper-v2 {
	button,
	.ant-btn {
		height: 48px!important;
		border-radius: 8px !important;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
	}
}

.mobile-modal-button {
	button,
	.ant-btn {
		border-radius: 8px;
		height: 56px !important;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		padding: 16px;
		line-height: 24px;
		cursor: pointer;
	}
}

.mobile-dialog-button {
	button,
	.ant-btn {
		height: 56px !important;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		padding: 16px;
		line-height: 24px;
		border: 1px solid rgba(255, 255, 255, 0.1) !important;
		cursor: pointer;
		background: rgba(255, 255, 255, 0.1) !important;
		border-radius: 4px !important;

		&:hover,
		:focus,
		:active {
			color: #fff;
			background: rgba(255, 255, 255, 0.1) !important;
			border: 1px solid rgba(255, 255, 255, 0.1) !important;
			outline: none !important;
			box-shadow: none !important;
		}

		&:focus {
			color: #fff;
			background: rgba(255, 255, 255, 0.1) !important;
			border: 1px solid rgba(255, 255, 255, 0.1) !important;
			outline: none !important;
			box-shadow: none !important;
		}

		&:active {
			color: #fff;
			background: rgba(255, 255, 255, 0.1) !important;
			border: 1px solid rgba(255, 255, 255, 0.1) !important;
			outline: none !important;
			box-shadow: none !important;
		}
	}
}

.mobile-modal-dismiss-btn {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	letter-spacing: 0.000909091em;
	color: #0361f0;
	cursor: pointer;
}

.mobile-modal-button {
	.btn-primary {
		background: $primary-color;
		box-shadow: 0 4px 15px rgba(3, 87, 238, 0.2);
		border: 1px solid $primary-color;
		color: #ffffff;
	}
}

.btn-m136 {
	width: 136px;
}

.ant-btn[disabled] {
	background: rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	color: rgba(0, 0, 0, 0.4);
	border: none;
}

button.btn-primary,
.ant-btn-primary {
	min-width: 80px;
	background: $primary-color;
	color: #ffffff;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	box-shadow: 0 4px 15px rgba(3, 87, 238, 0.2);
	border: 1px solid $primary-color;

	&:hover,
	:focus,
	:active {
		color: #fff;
		background: #40a9ff !important;
		border: 1px solid #40a9ff !important;
		outline: none !important;
		box-shadow: none !important;
	}

	&:focus {
		color: #fff;
		background: #40a9ff !important;
		border: 1px solid #40a9ff !important;
		outline: none !important;
		box-shadow: none !important;
	}

	&:active {
		color: #fff;
		background: #40a9ff !important;
		border: 1px solid #40a9ff !important;
		outline: none !important;
		box-shadow: none !important;
	}
}

button.btn-primary i {
	width: 4px;
	height: 16px;
	font-weight: 800;
	border-radius: 2px;
	margin-left: -3px;
	margin-right: 11px;
}

.ant-popover-buttons {
	.ant-btn-primary,
	.ant-btn {
		font-size: 15px;
		min-width: initial;
		height: initial;
	}
}

button.btn-search {
	min-width: 48px;
	background: $primary-color;
	color: #ffffff;
	box-shadow: 0 4px 15px rgba(3, 87, 238, 0.2);
	border: 1px solid $primary-color;
	border-left: 0;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

button.btn-inverted {
	background: transparent;
	color: $primary-color;
	font-size: 14px;
	border: 1px solid rgba(3, 87, 238, 0.1);
	padding: 7px 24px;
	border-radius: 4px;
	line-height: 24px;
	font-weight: 600;
	background: rgba(3, 87, 238, 0.1);

	cursor: pointer;

	> * {
		cursor: pointer;
	}
}

button.btn-light-blue {
	background: rgba(3, 87, 238, 0.1);
	border: none;
	color: $primary-color;
	font-size: 14px;
	padding: 10px 10px;
	min-width: 98px;
	align-items: center;
	justify-content: space-around;
	display: flex;
}

button.btn-heavy-blue {
	background: #0361f0;
	border: none;

	font-size: 14px;
	padding: 10px 10px;
	min-width: 98px;
	align-items: center;
	justify-content: space-around;
	display: flex;

	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
	mix-blend-mode: normal;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 8px;
}

button.btn-light-blue-mobile {
	background: none;
	border: none;
	color: $primary-color;
	font-size: 14px;
	padding: 10px 10px;
	min-width: 98px;
	align-items: center;
	justify-content: space-around;
	display: flex;
}

button.btn-search i {
	font-size: 21.57px;
}

.btn-secondary {
	background: rgba(157, 161, 171, 0.2) !important;
	border-radius: 4px;
	font-weight: 500;
	font-size: 14px;
	line-height: 14px;
	color: #9da1ab !important;
	border: none !important;
}

.btn-tertiary {
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	background: #0357ee1a;
	color: #0361f0;
	border: none !important;
}

.btn-tertiary:hover {
	background: #0357ee1a;
	color: #0361f0;
}

.btn-red {
	background: rgba(235, 87, 87, 0.2);
	border: 1px solid rgba(235, 87, 87, 0.2);
	border-radius: 4px;
	color: #eb5757;
	font-weight: 500;
	font-size: 15px;
	box-shadow: 0 4px 15px rgba(235, 87, 87, 0.2);

	&:hover {
		color: #eb5757;
		background: rgba(235, 87, 87, 0.1);
		border: 1px solid rgba(235, 87, 87, 0.02);
	}
}

.btn-block {
	width: 100%;
}

.cta-secondary-link {
	align-items: center;
	display: flex;
	justify-content: right;
}

.transparent-btn {
	width: 100%;
	font-weight: 600;
	color: #0361f0;
	border-radius: 8px;
	border: 2px solid #0361f0;
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 2px 0px;
}

.kamona-submit-btn {
	button,
	.ant-btn {
		height: 56px;
		border-radius: 4px !important;
		font-weight: 500;
		font-size: 14px;
		line-height: 18.2px;
	}
}

@media (max-width: 48em) {
	button,
	button.btn-primary,
	.ant-btn-primary {
		// max-width: 150px;
		min-width: auto;
	}
	.kamona-submit-btn {
		button,
		.ant-btn {
			border-radius: 8px !important;
		}
	}
}
