.general-tag {
	border-radius: 4px;
	color: white;
	padding: 4px 8px;
	font-size: 13px;
}

.amount-decimal {
	font-size: 14px;
	letter-spacing: 1px;
	font-weight: bold;
	color: #000000;
}

.overdraft-background-pattern,
.terminal-background-pattern {
	background-image: url('/assets/images/overdraft-pattern.png');
}

.cursor-pointer {
	cursor: pointer;
}

.top-card-addon {
	position: relative;
	top: 10px;
	padding: 4px 20px;
	border-bottom: none !important;
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
	height: 60px;
	box-shadow: none !important;

	>.flex-container {
		height: 100%;
		border-bottom: 0.7px solid #dbf0f4;
	}
}

.ant-popover-inner {
	border-radius: 8px;
}

.text-grey {
	color: #8798ad;
}

//.in-card-dropdown {
//  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
//  .ant-input-affix-wrapper,
//  .ant-picker {
//    //height: 32px!important;
//  }
//}

.custom-search-drop-down.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-affix-wrapper,
.ant-picker {
	height: 32px;
}

.mobile-tab-title {
	padding: 7px 16px;
	background: white;
	border-radius: 8px;
	font-weight: 500;
	font-size: 23px;
	border: 1px solid #e1ecfd;
	color: #b8b8bd;

	&.active {
		background: $primary-color;
		color: white;
	}
}

.terminal-type {
	&-pos {
		padding: 4px 8px;
		width: 39px;
		height: 20px;
		background: rgba(27, 151, 219, 0.1);
		border-radius: 4px;
		flex: none;
		order: 0;
		flex-grow: 0;
		font-style: normal;
		font-weight: 700;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		text-transform: uppercase;
		color: #1b97db;
	}

	&-web {
		padding: 4px 8px;
		width: 42px;
		height: 20px;
		background: rgba(155, 81, 224, 0.1);
		border-radius: 4px;
		flex: none;
		order: 0;
		flex-grow: 0;
		font-style: normal;
		font-weight: 700;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		text-transform: uppercase;
		color: #9b51e0;
	}
}

.trans-type {

	&-withdrawal,
	&-offline_withdrawal,
	&-offline_payment_deposit,
	&-offline_payment_withdrawal,
	&-card_carton_request,
	&-deposit,
	&-protected_transfer,
	&-terminal_repair_reversal,
	&-expense_card_request {
		padding: 4px 8px;
		width: 101px;
		height: 20px;
		background: rgba(3, 87, 238, 0.1);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		text-transform: uppercase;
		color: #0361f0;
	}

	&-default {
		padding: 4px 8px;
		width: 101px;
		height: 20px;
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		text-transform: uppercase;
		color: #0361f0;
		background-color: rgba(3, 87, 238, 0.1);
	}

	&-funds,
	&-fund_wallet,
	&-wallet_funds_transfer,
	&-card_transfer,
	&-saving_cashout,
	&-offline_deposit,
	&-card_packet_request {
		@extend .general-tag;
		padding: 4px 8px;
		width: 101px;
		height: 20px;
		background: rgba(27, 151, 219, 0.14);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		text-transform: uppercase;
		color: #1b97db;
	}

	&-transfer {
		@extend .general-tag;
		padding: 4px 8px;
		width: 101px;
		height: 20px;
		background: rgba(3, 87, 238, 0.1);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		text-transform: uppercase;
		color: #0361f0;
	}

	&-transfers {
		@extend .general-tag;
		padding: 4px 8px;
		width: 101px;
		height: 20px;
		background: rgba(3, 87, 238, 0.1);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		text-transform: uppercase;
		color: #0361f0;
	}

	&-bills,
	&-bill_payment,
	&-cashout,
	&-agent_protected_transfer,
	&-insurance_premium,
	&-pos_transfer,
	&-purchase,
	&-insurance,
	&-agent_cash_insurance_premium,
	&-area_manager_cashout,
	&-loan_repayment,
	&-merchant_pricing_acceptance {
		padding: 4px 8px;
		width: 102px;
		height: 20px;
		background: rgba(155, 81, 224, 0.1);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		text-transform: uppercase;
		color: #9b51e0;
	}

	&-debit,
	&-terminal_repair,
	&-quarantined_withdrawal_transaction,
	&-stolen_terminal {
		padding: 4px 8px;
		width: 49px;
		height: 20px;
		background: rgba(233, 68, 68, 0.1);
		border-radius: 4px;
		flex: none;
		order: 0;
		flex-grow: 0;
		margin: 16px 0px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		text-transform: uppercase;
		color: #e94444;
	}

	&-credit,
	&-settlement,
	&-savings,
	&-savings_cashout,
	&-terminal_activation_refund,
	&-quarantined_protected_transfer,
	&-activation_refund {
		padding: 4px 8px;
		width: 57px;
		height: 20px;
		background: rgba(30, 177, 45, 0.1);
		border-radius: 4px;
		flex: none;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		text-transform: uppercase;
		color: #1eb12d;
		order: 0;
		flex-grow: 0;
		margin: 16px 0px;
	}

	&-airtime,
	&-terminal_activation,
	&-loan_payment,
	&-cash_back_cashout,
	&-card_request_transaction,
	&-card_debit {
		padding: 4px 8px;
		width: 125px;
		height: 20px;
		background: rgba(240, 137, 34, 0.1);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		text-transform: uppercase;
		color: #f08922;
	}

	&-cash_back_cashout {
		@extend .general-tag;
		background: rgba(244, 128, 60, 0.14);
		color: #f4803c;
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
	}

	&-loan_transfer {
		@extend .general-tag;
		background: rgba(3, 97, 240, 0.14);
		color: #fff;
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
	}
}

.tag {

	&-failed,
	&-not_reversed,
	&-delivery_failed,
	&-declined,
	&-suspended {
		@extend .general-tag;
		background: rgba(233, 68, 68, 0.1);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #e94444;
	}

	&-owned {
		@extend .general-tag;
		background: rgba(155, 81, 224, 0.14);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #9b51e0;
	}

	&-leased,
	&-funds,
	&-reversed {
		@extend .general-tag;
		background: rgba(27, 151, 219, 0.14);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #1b97db;
	}

	&-approval {
		@extend .general-tag;
		background: rgba(27, 151, 219, 0.14);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #1b97db;
	}

	&-not_insured {
		@extend .general-tag;
		background: rgba(233, 68, 68, 0.1);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #e94444;
		font-size: 11px;
	}

	&-rejected_by_sc,
	&-closed {
		@extend .general-tag;
		background: rgba(233, 68, 68, 0.1);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #e94444;
		font-size: 11px;
	}

	&-pending_sc_approval {
		@extend .general-tag;
		background: rgba(244, 128, 60, 0.14);
		color: #f4803c;
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
	}

	&-success {
		@extend .general-tag;
		background: rgba(36, 240, 3, 0.14);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #1eb12d;
	}

	&-subscribed,
	&-solved,
	&-successful {
		@extend .general-tag;
		background: rgba(36, 240, 3, 0.14);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #1eb12d;
	}

	&-progress {
		@extend .general-tag;
		background: rgba(93, 127, 141, 0.14);
		color: #5d7f8d;
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
	}

	&-approved_by_sc {
		@extend .general-tag;
		background: rgba(36, 240, 3, 0.14);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #1eb12d;
	}

	&-credit {
		@extend .general-tag;
		background: rgba(36, 240, 3, 0.14);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #1eb12d;
	}

	&-debit {
		@extend .general-tag;
		background: rgba(233, 68, 68, 0.1);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #e94444;
	}

	&-subscribe {
		@extend .general-tag;
		background: rgba(36, 240, 3, 0.14);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #1eb12d;
	}

	&-insured {
		@extend .general-tag;
		background: rgba(36, 240, 3, 0.14);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #1eb12d;
	}

	&-accepted,
	&-refund_in_progress {
		@extend .general-tag;
		background: rgba(36, 240, 3, 0.14);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #1eb12d;
	}

	&-refunded {
		@extend .general-tag;
		background: rgba(36, 240, 3, 0.34);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #1eb12d;
	}

	&-pending,
	&-investigating {
		@extend .general-tag;
		background: rgba(244, 128, 60, 0.14);
		color: #f4803c;
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		white-space: nowrap;
	}

	&-open {
		@extend .general-tag;
		background: rgba(244, 128, 60, 0.14);
		color: #f4803c;
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
	}

	&-awaiting {
		@extend .general-tag;
		background: #f4803c;
		background: rgba(244, 128, 60, 0.14);
		color: #f4803c;
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
	}

	&-draft {
		@extend .general-tag;
		background: rgba(93, 127, 141, 0.14);
		color: #5d7f8d;
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
	}

	&-not_subscribed,
	&-on_hold {
		@extend .general-tag;
		background: rgba(93, 127, 141, 0.14);
		color: #5d7f8d;
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
	}

	&-inactive {
		@extend .general-tag;
		background: rgba(93, 127, 141, 0.14);
		color: #5d7f8d;
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
	}

	&-not_insured,
	&-deactivated,
	&-debit {
		@extend .general-tag;
	}

	&-on_hold,
	&-awaiting_otp {
		@extend .general-tag;
		background: rgba(11, 50, 117, 0.1);
		color: rgba(11, 50, 117, 0.4);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
	}

	&-insured {
		@extend .general-tag;
		background: rgba(30, 177, 45, 0.1);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #1eb12d;
	}

	&-completed,
	&-delivered {
		@extend .general-tag;
		background: rgba(36, 240, 3, 0.14);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #1eb12d;
	}

	&-active,
	&-activated {
		@extend .general-tag;
		background: rgba(36, 240, 3, 0.14);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #1eb12d;
	}

	&-approved {
		@extend .general-tag;
		background: rgba(36, 240, 3, 0.14);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #1eb12d;
	}

	&-recovery {
		@extend .general-tag;
		background: rgba(233, 68, 68, 0.1);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #e94444;
	}

	&-expired {
		@extend .general-tag;
		background: rgba(233, 68, 68, 0.1);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #e94444;
	}

	&-rejected {
		@extend .general-tag;
		color: #dc3545;
		background: rgba(220, 53, 69, 0.14);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
	}

	&-deactivated,
	&-terminated {
		@extend .general-tag;
		color: #dc3545;
		background: rgba(220, 53, 69, 0.14);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
	}

	&-default {
		@extend .general-tag;
		background: rgba(240, 137, 34, 0.1);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #f08922;
	}

	&-deactivated_subscription {
		@extend .general-tag;
		background: rgba(240, 137, 34, 0.1);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #f08922;
	}

	&-pending_payment {
		@extend .general-tag;
		background: rgba(240, 137, 34, 0.1);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #f08922;
	}

	&-paid {
		@extend .general-tag;
		background: rgba(36, 240, 3, 0.14);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #1eb12d;
	}

	&-in_progress {
		@extend .general-tag;
		background: rgba(240, 137, 34, 0.1);
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
		color: #f08922;
	}

	&-pending_requery {
		@extend .general-tag;
		background: rgba(244, 128, 60, 0.14);
		color: #f4803c;
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		line-height: 8px;
		letter-spacing: -0.005em;
	}

	&-expired_then_declined {
		@extend .general-tag;
		background: #dc3545;
		font-style: normal;
		font-weight: 500;
		font-size: 11px;
		letter-spacing: -0.025em;
		color: #ffffff;
		padding: 2px;
		text-align: center;
		vertical-align: center;
	}

	&-on_track,
	&-unassigned {
		@extend .general-tag;
		background: rgba($primary-color, 0.1);
		font-style: normal;
		font-weight: 700;
		text-transform: capitalize;
		font-size: 11px;
		letter-spacing: -0.025em;
		color: $primary-color;
		padding: 6px 8px;
		text-align: center;
		vertical-align: center;
	}

	&-running_late {
		@extend .general-tag;
		background: rgba(244, 128, 60, 0.14);
		font-style: normal;
		font-weight: 700;
		text-transform: capitalize;
		font-size: 11px;
		letter-spacing: -0.025em;
		color: #f4803c;
		padding: 6px 8px;
		text-align: center;
		vertical-align: center;
	}

	&-out_of_tat {
		@extend .general-tag;
		color: #dc3545;
		background: rgba(220, 53, 69, 0.14);
		font-style: normal;
		font-weight: 700;
		text-transform: capitalize;
		font-size: 11px;
		letter-spacing: -0.025em;
		padding: 6px 8px;
		text-align: center;
		vertical-align: center;
	}
}

.tag-approvals {
	&-deposit {
		@extend .general-tag;
		background: rgba(3, 87, 238, 0.1);
		border-radius: 4px;
		width: fit-content;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: -0.005em;
		color: #0361f0;
		padding: 4px 8px;

	}

	&-default {
		@extend .general-tag;
		background: rgba(3, 87, 238, 0.1);
		border-radius: 4px;
		width: fit-content;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: -0.005em;
		color: #0361f0;
		padding: 4px 8px;
		
	}

	&-overdraft {
		@extend .general-tag;
		background: rgba(155, 81, 224, 0.14);
		border-radius: 4px;
		width: fit-content;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: -0.005em;
		color: #9b51e0;
		padding: 4px 8px;
	}

	&-approval {
		@extend .general-tag;
		background: rgba(30, 177, 45, 0.1);
		border-radius: 4px;
		width: fit-content;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: -0.005em;
		color: #1eb12d;
		padding: 4px 8px;
	}
}

.ant-breadcrumb {
	font-size: 12px;
}

.ant-dropdown {
	ul {
		border-radius: 8px;
		padding: 8px 15px;

		li {
			border-bottom: 0.7px solid #dbf0f4;
			padding: 8px 0;
			gap: 16px;
			font-size: 13px;

			&:last-child {
				border-bottom: none;
			}
		}
	}
}

.capitalize {
	text-transform: uppercase;
}

.grey-icons {
	svg {
		path {
			fill: $primary-text-color;
		}
	}
}

.white-text {
	color: white;
}

.white-icons {
	svg {
		path {
			fill: #ffffff;
		}
	}
}

.filled-icons {
	svg {
		path {
			fill: $primary-color;
		}
	}
}

.custom-global-error-state {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 17px 14px;
	gap: 10px;
	min-height: 58px;
	background: rgba(248, 43, 43, 0.05);
	border-radius: 8px;
	margin-bottom: 20px;
}

.custom-global-error-state-title {
	font-weight: 700 !important;
}

.custom-global-error-state-font {
	font-style: normal;
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	color: #e94444;
}

.global-error-state,
.ant-alert-error {
	background-color: #fbdfe2;
	//   border: 0.6px solid rgba(212, 88, 102, 0.34);
	//   box-shadow: 0px 4px 4px rgba(212, 88, 102, 0.18);
	border-radius: 8px;
	font-size: 12px;
	min-height: 68px;

	.ant-alert-message {
		font-size: 14px;
		color: #d45866;
		line-height: 17px;
		font-weight: 500;
	}

	.ant-alert-description {
		font-size: 12px;
	}
}

.active-badge {
	width: 47px;
	font-style: normal;
	font-weight: 700;
	font-size: 11px;
	line-height: 8px;
	color: #1eb12d;
	background: rgba(30, 177, 45, 0.1);
	border-radius: 4px;
	text-align: center;
}

.blue-badge {
	width: 47px;
	font-style: normal;
	font-weight: 700;
	font-size: 11px;
	line-height: 8px;
	color: #0361f0;
	background: rgba(3, 87, 238, 0.1);
	border-radius: 4px;
	text-align: center;
	padding: 4px 8px;
}

.extra-badge-width {
	width: 85px !important;
}

.extra-badge-widthI {
	width: 150px !important;
}

.expired-badge {
	font-style: normal;
	font-weight: 700;
	font-size: 11px;
	line-height: 8px;
	padding: 10px;
	color: $red;
	background: $background-color-red;
	border-radius: 4px;
	text-align: center;
	text-transform: uppercase;
}

.badge {
	font-style: normal;
	font-weight: 700;
	font-size: 11px;
	line-height: 8px;
	padding: 10px;
	color: #8598BA;
	background: #E7EAF1;
	border-radius: 4px;
	text-align: center;
}

.leased-badge {
	font-style: normal;
	font-weight: 700;
	font-size: 11px;
	line-height: 8px;
	padding: 10px;
	color: #1b97db;
	background: rgba(27, 151, 219, 0.05);
	border-radius: 4px;
	text-align: center;
	text-transform: uppercase;
}

.awaiting-badge-orange {
	font-style: normal;
	font-weight: 700;
	font-size: 11px;
	line-height: 8px;
	padding: 10px;
	color: $orange;
	background: $background-color-green;
	border-radius: 4px;
	text-align: center;
	text-transform: uppercase;
}

.awaiting-badge-green {
	font-style: normal;
	font-weight: 700;
	font-size: 11px;
	line-height: 8px;
	padding: 10px;
	color: $green;
	background: $background-color-green;
	border-radius: 4px;
	text-align: center;
	text-transform: uppercase;
}

.awaiting-badge-red {
	font-style: normal;
	font-weight: 700;
	font-size: 11px;
	line-height: 8px;
	padding: 10px;
	color: $red;
	background: $background-color-red;
	border-radius: 4px;
	text-align: center;
	text-transform: uppercase;
}

.pending-badge {
	font-style: normal;
	font-weight: 700;
	font-size: 11px;
	line-height: 8px;
	padding: 10px;
	color: #1b97db;
	background: rgba(27, 151, 219, 0.05);
	border-radius: 4px;
	text-align: center;
	text-transform: uppercase;
}

.approved_by_brm-badge,
.approved_by_business-owner-badge {
	width: 47px;
	font-style: normal;
	font-weight: 700;
	font-size: 11px;
	line-height: 8px;
	color: #1eb12d;
	background: rgba(30, 177, 45, 0.1);
	border-radius: 4px;
	text-align: center;
}

.declined_by_brm-badge,
.declined_by_business_owner-badge {
	width: 47px;
	font-style: normal;
	font-weight: 700;
	font-size: 11px;
	line-height: 8px;
	color: $red;
	background: $background-color-red;
	border-radius: 4px;
	text-align: center;
}

.inactive-badge {
	width: 57px;
	font-style: normal;
	font-weight: 500;
	font-size: 11px;
	color: #ffffff;
	background: #7f868a;
	text-align: center;
	border-radius: 4px;
}

.no-request {
	width: 57px;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	color: #063a4f;
	opacity: 0.3;
	text-align: center;
	border-radius: 4px;
}

.offline-title {
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	/* identical to box height */
	letter-spacing: 0.0115367em;
	/* Text Blue */
	color: #063a4f;
}

.card {
	background: #ffffff;
	border: 0.5px solid rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	box-sizing: border-box;
	box-shadow: 0px 2px 3px rgba(13, 79, 100, 0.12);
	border-radius: 8px;
}

.info-alert-box {
	background: rgba(3, 97, 240, 0.09);
	border-radius: 8px;
	height: 48px;
}

.face-canvas {
	position: absolute;
	left: 0;
	top: 0;
}

@media screen and (max-width: 1059px) {
	.large-summary-card-re-adjust {
		padding-left: 0px !important;
	}
}

@media screen and (max-width: 1209px) {
	.large-summary-card-height-re-adjust {
		min-height: 180px !important;
	}
}

.step-note {
	position: relative;
	top: 0.8em;
}

.step-note h6 {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 22px;
	letter-spacing: 0.0126316em;
	/* Text Blue */
	color: #063a4f;
	mix-blend-mode: normal;
}

.large-summary-card {
	min-height: 160px;
	box-sizing: border-box;
	//   padding-left: 13px;

	.vertical-flex {
		padding: 20px;
	}

	.amount {
		font-size: 40px;
		font-weight: 500;
		color: #063a4f;
	}
}

.small-card-amount {
	position: relative;
	top: -0.4em;
}

.summary-title {
	font-size: 16px;
	color: #8798ad;
	line-height: 19px;
	letter-spacing: 0.713333px;
}

//.title {
//  font-size: 20px;
//  line-height: 24px;
//  font-weight: 500;
//}

.adjust-summary {
	position: relative;
	left: 70px;
}

@media screen and (max-width: 991px) {
	.adjust-summary-II {
		position: relative;
		left: 70px !important;
	}
}

.naira {
	font-style: normal;
	font-weight: normal;
	font-size: 34px;
	color: $faded-grey;
	position: relative;
	top: -1px;
}

.mobile-naira {
	font-weight: normal;
	font-size: 23.8235px;
	line-height: 28px;
	color: $faded-grey;
}

.naira-sm {
	font-style: normal;
	font-weight: normal;
	font-size: 30px;
	color: $faded-grey;
	position: relative;
	top: -1px;
}

.naira-xs {
	font-style: normal;
	font-weight: normal;
	font-size: 23.8235px;
	line-height: 28px;
	color: $faded-grey;
	position: relative;
	top: -1px;
}

.amount-sm {
	font-size: 30px;
	font-weight: 500;
	color: #063a4f;
}

.inner-content {
	//padding: 22px 49px 50px 49px;
	padding: 20px 32px 50px;
	max-width: 1400px;
	margin: 28px auto;
	min-width: 330px;
}

.inner-content-no-header {
	padding: 0 !important;
	margin: 0 auto !important;
	min-width: 320px !important;
}

.inner-notification {
	padding: 0 !important;
	margin: 0 !important;
	max-width: 1400px;
	min-width: 330px;
}

.ant-layout-content {
	overflow-x: hidden;
	overflow-y: scroll;
	height: 100%;
}

.empty-state {
	height: 100%;
	align-items: center;
	justify-content: center;
	display: flex;

	.state-subtitle,
	.state-title {
		font-weight: 500;
		letter-spacing: 0.0126316em;
		font-size: 16px;
		line-height: 19px;
	}

	svg {
		height: 101px;
	}
}

.active-tag {
	background: #43be09;
	border-radius: 4px;
	color: white;
	padding: 0px 4px;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title,
.ant-switch,
.ant-table,
.ant-table-footer,
.ant-table-thead>tr>th {
	color: $primary-text-color;
}

.inline-tab {
	padding: 8px 22px;
	font-size: 13px;
	cursor: pointer;
}

.inline-active-tab {
	background: $primary-color;
	color: white;
	border-radius: 30px;
}

.header-text {
	font-size: 13px;
	line-height: 15px;
	color: #5d7f8d;
}

.add-pointer {
	cursor: pointer !important;
}

.responsive-height-85 {
	height: 85% !important;
}

.responsive-height-90 {
	height: 90% !important;
}

.disable-click {
	pointer-events: none;
	opacity: 0.5;
}

@media (max-width: 48em) {
	.naira {
		font-size: 23px;
	}

	.inner-content {
		padding: 10px 21px 21px 21px;
		margin: 0 auto 24px;
		height: 100%;
		height: -moz-available;
		height: -webkit-fill-available;
		height: fill-available;
	}

	.portable-action-card {
		width: 214px;
		background: #ffffff;
		border: 0.7px solid #e0e8f4;
		box-sizing: border-box;
		border-radius: 8px;
		cursor: pointer;
	}

	.portable-action-card-mobile {
		background: #ffffff;
		border: 0.7px solid #e0e8f4;
		box-sizing: border-box;
		border-radius: 8px;
		cursor: pointer;
	}

	.large-summary-card {
		.amount {
			font-size: 24px;
			font-weight: 500;
		}
	}
}

// responsive queries
.visible-xxs,
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
	display: none !important;
}

@media (max-width: 48em) {
	.visible-xs {
		display: block !important;
	}

	.display-flex {
		display: flex !important;
	}

	.row.visible-xs {
		display: flex !important;
		display: -webkit-flex !important;
		display: -ms-flexbox !important;
		display: -webkit-box !important;
		display: flex !important;
	}

	table.visible-xs {
		display: table !important;
	}

	tr.visible-xs {
		display: table-row !important;
	}

	th.visible-xs,
	td.visible-xs {
		display: table-cell !important;
	}
}

@media (min-width: 48em) and (max-width: 62em) {
	.visible-sm {
		display: block !important;
	}

	.row.visible-sm {
		display: flex !important;
		display: -webkit-flex !important;
		display: -ms-flexbox !important;
		display: -webkit-box !important;
		display: flex !important;
	}

	table.visible-sm {
		display: table !important;
	}

	tr.visible-sm {
		display: table-row !important;
	}

	th.visible-sm,
	td.visible-sm {
		display: table-cell !important;
	}
}

@media (min-width: 62em) and (max-width: 75em) {
	.visible-md {
		display: block !important;
	}

	.row.visible-md {
		display: flex !important;
		display: -webkit-flex !important;
		display: -ms-flexbox !important;
		display: -webkit-box !important;
		display: flex !important;
	}

	table.visible-md {
		display: table !important;
	}

	tr.visible-md {
		display: table-row !important;
	}

	th.visible-md,
	td.visible-md {
		display: table-cell !important;
	}
}

@media (min-width: 75em) {
	.visible-lg {
		display: block !important;
	}

	.row.visible-lg {
		display: flex !important;
		display: -webkit-flex !important;
		display: -ms-flexbox !important;
		display: -webkit-box !important;
		display: flex !important;
	}

	table.visible-lg {
		display: table !important;
	}

	tr.visible-lg {
		display: table-row !important;
	}

	th.visible-lg,
	td.visible-lg {
		display: table-cell !important;
	}
}

@media (max-width: 48em) {
	.hidden-xs {
		display: none !important;
	}
}

//@media screen and (max-width: 393px) {
//  .hidden-xxs {
//    display: none !important;
//  }
//  .visible-xxs {
//    display: block !important;
//  }
//}

@media (min-width: 48em) and (max-width: 62em) {
	.hidden-sm {
		display: none !important;
	}
}

.hidden {
	display: none;
}

@media (min-width: 62em) and (max-width: 75em) {
	.hidden-md {
		display: none !important;
	}
}

@media (min-width: 75em) {
	.hidden-lg {
		display: none !important;
	}
}

.breadcrumb-spacing {
	margin-top: -3px;
}

.button-size-148 {
	min-width: 148px;
}

.breadcrumb-spacing p {
	font-size: 12px;
	color: #0361f0;
}

.floating-button {
	margin-top: -3.3em;
}

.floating-button div {
	float: right;
}

.floating-button-right {
	float: right;
}

.button-text {
	margin-left: 6.2px;
	position: relative;
	top: -3px;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	color: #ffffff;
}

.button-size-145 {
	min-width: 145px !important;
}

.button-size-144 {
	min-width: 144px !important;
}

.button-size-136 {
	min-width: 136px !important;
}

.button-size-160 {
	min-width: 160px !important;
}

@media screen and (max-width: 960px) {
	.button-size-145 {
		min-width: 115px !important;
	}
}

@media screen and (max-width: 890px) {
	.button-size-145 {
		min-width: 65px !important;
	}

	.button-text {
		display: none;
	}
}

.profile-divider {
	height: 1px;
	background: rgba(11, 50, 117, 0.1);
	flex: none;
	order: 1;
	align-self: stretch;
	flex-grow: 0;
	margin: 0px 0px;
}

.primary-background {
	background: $primary-color url("/assets/images/svgs/hero-bg-pattern.svg");
	min-height: 100vh;
	overflow-y: auto;
	position: relative;
}

.failed-background {
	background: rgba(0, 0, 0, 0.8) url("/assets/images/svgs/faile-bg.svg");
	min-height: 100vh;
	overflow-y: auto;
	position: relative;
}
