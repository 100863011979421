@tailwind components;

html,
body {
  font-size: 14px;
  font-family: $font-inter;
  background: #f1f7fe;
  color: #063a4f !important;
  -webkit-overflow-scrolling: auto;
  scroll-behavior: smooth;
  @apply antialiased;
}

body,
.ant-layout {
  background: #f1f7fe;
}

html {
  scroll-behavior: smooth;
}

.app-layout {
  // border-top: 4px solid $primary-color;
}

// breadcumb active link should be blue

.ant-breadcrumb a {
  color: $primary-color;
  transition: color 0.3s;
}

.ant-breadcrumb > nz-breadcrumb-item:last-child a {
  color: rgba(0, 0, 0, 0.45) !important;
  transition: color 0.3s;
}

.filter-holder {
  width: 115px;
  height: 56px;
  background: rgba(3, 87, 238, 0.1);
  border-radius: 4px;
  color: #0361f0;
  cursor: pointer;
}

.primary-label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* Primary Blue */
  color: #0361f0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
input,
button,
table,
th,
tr,
tbody,
thead,
div,
textarea {
  font-family: $font-inter;
}

h1,
h2,
h3,
h4,
h5,
h6,
.ant-list-item,
.ant-breadcrumb > nz-breadcrumb-item:last-child,
.ant-popover-message,
.ant-popover-inner-content,
table {
  color: $primary-text-color;
}

h2 {
  font-weight: 500;
  line-height: 27px;
}

a {
  color: $primary-color;
}

.full-width {
  width: 100%;
}

.section-header {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.section-description {
  font-size: 14px;
  line-height: 150.5%;
  color: rgba(0, 0, 0, 0.9);
}

/* .ant-breadcrumb>nz-breadcrumb-item:last-child a {
	color: $primary-color;
} */

.ant-alert-info {
  border: none;
  background: rgba(3, 97, 240, 0.09);
  border-radius: 8px;
  height: 48px;
  height: inherit;
}

.dashboard-bonanza-card {
  padding: 17px 22px;
  min-height: 51px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(14, 79, 177, 0.12);
  border-radius: 4px;
}

.dashboard-bonanza-text {
  font-style: normal;
  margin-left: 12px;
  position: relative;
  top: 0.1em;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.71px;
  /* Text Blue */
  color: #063a4f;
}

.dashboard-survey-text {
  font-style: normal;
  position: relative;
  top: 0.1em;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.71px;
  /* Text Blue */
  color: #063a4f;
}

.dashboard-bonanza-link {
  font-style: normal;
  cursor: pointer;
  position: relative;
  top: 0.1em;
  margin-left: 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.71px;
  /* Primary Blue */
  color: #0361f0;
  opacity: 0.8;
}

.offline-sub-text {
  font-style: normal;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 15px;
  letter-spacing: 0.0115367em;
  /* Header Text */
  //color: #5D7F8D;
}

.page-title {
  text-transform: capitalize;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.9);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}

.cap-text {
  text-transform: capitalize;
}

.mobile-title-margin {
  margin-top: -0.35em;
}

.custom-divider {
  height: 0px;
  border: 1px solid #e1ecfd;
}

.custom-divider-width {
  width: 120px !important;
  margin-top: -0.5em;
}

.custom-divider-adjustment {
  margin-top: -0.1em;
}

.flex-container {
  display: flex;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.j-c-flex-end {
  justify-content: flex-end;
}

.j-c-center {
  justify-content: center;
}

.j-c-space-between {
  justify-content: space-between;
}

.j-c-space-around {
  justify-content: space-around;
}

.j-c-start {
  justify-content: flex-start;
}

.j-c-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.self-align-center {
  align-self: center;
}

.flex-1 {
  flex: 1;
}

.f-13 {
  font-size: 13px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-20 {
  font-size: 20px;
}

.p-20 {
  padding: 20px !important;
}

.p-22p18 {
  padding: 22px 18px;
}

.p-t-52 {
  padding-top: 52px;
}

.p-t-56 {
  padding-top: 56px;
}

//.p-l-r-40 {
//  padding-left: 40px;
//  padding-right: 40px;
//}
.p-l-r-40 {
  padding-left: 24px;
  padding-right: 24px;
}

//.p-t-b-52 {
//  padding-top: 52px;
//  padding-bottom: 52px;
//}

.p-t-b-52 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.p-t-52 {
  padding-top: 52px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-18 {
  padding: 18px;
}

.p-14 {
  padding: 14px;
}

// .p-5 {
// 	padding: 5px !important;
// }

.p-t-0 {
  padding-top: 0px !important;
}

.p-t-16 {
  padding-top: 16px;
}

.p-16 {
  padding: 16px;
}

.gap-20 {
  gap: 20px;
}

.gap-24 {
  gap: 24px;
}

.gap-16 {
  gap: 16px;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px;
}

.gap-8 {
  gap: 8px !important;
}

.gap-7 {
  gap: 7px;
}

.m-t-4 {
  margin-top: 4px;
}

.close-modal-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  letter-spacing: 0.0126316em;
  /* Primary Blue */
  color: #0361f0;
  mix-blend-mode: normal;
}

.m-t-6 {
  margin-top: 6px;
}

.m-t-7 {
  margin-top: 7px;
}

.m-t-8 {
  margin-top: 8px;
}

.kamona-mobile-nav-wrapper {
  height: 58px;
}

.m-t-81 {
  margin-top: 81px;
}

.m-t-80 {
  margin-top: 80px;
}

.m-t-9 {
  margin-top: 9px;
}

.m-t-12 {
  margin-top: 12px;
}

.m-t-11 {
  margin-top: 11px !important;
}

.m-t-10 {
  margin-top: 10px;
}

.mobile-form-wrapper {
  @apply relative;
  @apply min-h-screen;
  @apply flex;
  @apply flex-col;
  @apply justify-between;
}

.mobile-content-bg {
  @apply grow;
  @apply bg-white;
  @apply flex;
  @apply flex-col;
  @apply justify-between;
  @apply h-full;
  @apply px-[20px];
  @apply pt-[24px];
  @apply pb-[32px];
  @apply rounded-t-[16px];
  @apply relative;
  box-shadow: 0px -2px 14px rgba(53, 113, 205, 0.09);
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-2P9 {
  margin-right: 2.9em;
}

.m-r-2P4 {
  margin-right: 2.45em;
}

.custom-modal-body-content-spacing {
  margin-top: 10px;
}

.custom-modal-body-button-spacing {
  margin-top: 20px;
}

.m-t-14 {
  margin-top: 14px;
}

.m-t-2 {
  margin-top: 2px;
}

.over-flow-hidden {
  overflow: hidden;
  height: 100%;
}

.visibility-hidden {
  visibility: hidden;
}

.m-t-20 {
  margin-top: 20px;
}

.airtime-text {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.21333px;
  text-transform: uppercase;
  color: rgba(3, 97, 240, 0.45);
}

.paycode-custom-text {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  position: relative;
  top: -0.8em;
  letter-spacing: 1.21333px;
  text-transform: uppercase;
  color: #063a4f;
  opacity: 0.45 !important;
}

.verified-name {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 1.21333px;
  color: #063a4f;
  opacity: 0.75 !important;
}

.offline-success {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  /* Text Blue */
  color: #063a4f;
}

.offline-sub-success {
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 160% */
  /* Text Blue */
  color: #063a4f;
}

.dialog-alert-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  color: #063a4f;
}

.paycode-amount {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  position: relative;
  top: -0.4em;
  /* identical to box height */
  letter-spacing: 1px;
  /* Text Blue */
  color: #063a4f;
  opacity: 0.5;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-18 {
  margin-bottom: 18px;
}

.m-b-24 {
  margin-bottom: 24px;
}

.m-b-28 {
  margin-bottom: 28px;
}

.m-b-32 {
  margin-bottom: 32px;
}

.m-b-52 {
  margin-bottom: 52px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.m-b-36 {
  margin-bottom: 36px;
}

.m-b-35 {
  margin-bottom: 35px;
}

.m-t-np8 {
  margin-top: -0.8em;
}

.m-t-24 {
  margin-top: 24px;
}

.m-t-23 {
  margin-top: 23px;
}

.m-t-n-8 {
  margin-top: -8px !important;
}

.m-t-27 {
  margin-top: 27px;
}

.m-t-26 {
  margin-top: 26px;
}

.m-t-n2em {
  margin-top: -2em;
}

.no-wrap {
  word-break: normal;
}

.custom-image-card {
  background: #ffffff;
  border-radius: 16px;
}

.m-t-27 {
  margin-top: 27px;
}

.m-t-22 {
  margin-top: 22px;
}

.m-t-21 {
  margin-top: 21px;
}

.m-t-21 {
  margin-top: 21px;
}

.m-t-3 {
  margin-top: 3px;
}

.m-t-1 {
  margin-top: 1px;
}

.details-container {
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 8px !important;
}

.m-t-25 {
  margin-top: 25px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-t-52 {
  margin-top: 52px;
}

.m-t-13 {
  margin-top: 13px;
}

.m-t-38 {
  margin-top: 38px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-t-29 {
  margin-top: 29px;
}

.m-b-70 {
  margin-bottom: 70px;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-400 {
  margin-bottom: 400px;
}

.m-b-3 {
  margin-bottom: 3px;
}

.m-b-150 {
  margin-bottom: 150px;
}

.m-b-119 {
  margin-bottom: 119px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-t-36 {
  margin-top: 36px;
}

nz-alert {
  margin-bottom: 16px;
}

.m-t-37 {
  margin-top: 37px;
}

.m-t-39 {
  margin-top: 39px;
}

.m-t-27 {
  margin-top: 27px;
}

.m-t-28 {
  margin-top: 28px;
}

.details-icon-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  line-height: 15px;
  color: #19191a;
}

.m-t-n-p5em {
  margin-top: -0.5em;
}

.over-flow-x {
  overflow-x: auto;
}

.m-t-16 {
  margin-top: 16px;
}

.m-t-n-16 {
  margin-top: -16px;
}

.m-l-n-16 {
  margin-left: -16px;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-18 {
  margin-top: 18px;
}

.m-t-19 {
  margin-top: 19px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-n-15 {
  margin-top: -15px;
}

.m-t-17 {
  margin-top: 17px;
}

.m-t-31 {
  margin-top: 31px;
}

.m-t-32 {
  margin-top: 32px;
}

.pull-card {
  position: relative;
  top: -3em;
}

.tab-badge-inactive {
  background: rgba(11, 50, 117, 0.7);
}

.tab-badge-active {
  background: rgba(3, 97, 240, 0.8);
}

.tab-badge {
  min-width: 20px;
  height: 19px;
  border-radius: 4.42105px;
  font-style: normal;
  font-weight: bold;
  font-size: 11.0526px;
  line-height: 13px;
  color: #ffffff;
  text-align: center;
  padding: 2px 1px;
  margin: 10px 0px;
}

.m-opacity-full {
  opacity: 1;
}

.m-opacity-half {
  opacity: 0.4;
}

.m-t-33 {
  margin-top: 33px;
}

.p-t-32 {
  padding-top: 32px;
}

.p-t-3 {
  padding-top: 3px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-60 {
  padding-top: 60px;
}

.p-l-42 {
  padding-left: 42px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-l-0 {
  padding-left: 0px !important;
}

.m-l-30 {
  margin-left: 30px;
}

.m-l-32 {
  margin-left: 32px;
}

.m-b-12 {
  margin-bottom: 12px;
}

.m-b-14 {
  margin-bottom: 14px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-32 {
  padding-right: 32px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-r-60 {
  padding-right: 60px;
}

.p-r-0 {
  padding-right: 0px !important;
}

.f-s-12 {
  font-size: 12px;
}

.m-t-34 {
  margin-top: 34px;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.display-flex {
  display: flex !important;
}

.display-none {
  display: none;
}

.m-t-42 {
  margin-top: 42px;
}

.m-t-41 {
  margin-top: 41px;
}

.m-t-43 {
  margin-top: 43px;
}

.m-t-44 {
  margin-top: 44px;
}

.m-t-45 {
  margin-top: 45px;
}

.m-t-46 {
  margin-top: 46px;
}

.m-t-44 {
  margin-top: 44px;
}

.m-t-48 {
  margin-top: 48px;
}

.m-t-49 {
  margin-top: 49px;
}

.m-t-92 {
  margin-top: 92px;
}

.m-t-97 {
  margin-top: 97px;
}

.m-t-90 {
  margin-top: 90px;
}

.m-t-114 {
  margin-top: 114px;
}

.m-t-112 {
  margin-top: 112px;
}

.m-t-143 {
  margin-top: 143px;
}

.m-t-148 {
  margin-top: 148px;
}

.m-t-107 {
  margin-top: 107px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-b-60 {
  padding-bottom: 60px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-b-8 {
  padding-bottom: 8px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-b-12 {
  padding-bottom: 12px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-b-52 {
  padding-bottom: 52px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-l-12 {
  padding-left: 12px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-l-7 {
  padding-left: 7px;
}

.p-l-13 {
  padding-left: 13px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-t-6 {
  padding-top: 6px;
}

.p-t-8 {
  padding-top: 8px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-1 {
  padding-left: 1px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-20 {
  padding-right: 20px;
}

.m-0 {
  margin: 0px;
}

.m-t-119 {
  margin-top: 119px;
}

.m-t-133 {
  margin-top: 133px;
}

.m-t-109 {
  margin-top: 109px;
}

.m-t-320 {
  margin-top: 320px;
}

.m-t-208 {
  margin-top: 208px;
}

.m-t-220 {
  margin-top: 220px;
}

.p-t-220 {
  padding-top: 220px;
}

.m-t-126 {
  margin-top: 126px;
}

.m-t-122 {
  margin-top: 122px;
}

.m-t-168 {
  margin-top: 168px;
}

.m-t-178 {
  margin-top: 178px;
}

.m-t-180 {
  margin-top: 180px;
}

.m-t-200 {
  margin-top: 200px;
}

.m-t-161 {
  margin-top: 161px;
}

.m-t-53 {
  margin-top: 53px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-t-48 {
  margin-top: 48px;
}

.cor-p {
  cursor: pointer;
}

.p-rel {
  position: relative;
}

.p-tn-4 {
  top: -4em;
}

.p-tn-7 {
  top: -7em;
}

.p-tn-18 {
  top: -18em;
}

.c-white {
  color: #ffffff;
}

.c-thin-black {
  color: #063a4f !important;
}

.c-black {
  color: #1e2e3f;
}

.c-blue {
  color: #063a4f;
}

.c-dark-blue {
  color: #0361f0;
}

.c-grey {
  color: #9da1ab;
}

.p-tn-3 {
  top: -3em;
}

.p-tn-2 {
  top: -2em;
}

.p-tn-1D4 {
  top: -1.4em;
}

.p-tn-5P {
  top: -5px;
}

.p-t-12 {
  padding-top: 12px;
}

.p-tn-12P {
  top: -12px;
}

.p-tn-10 {
  top: -10em;
}

.p-tn-14 {
  top: -14em;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-57 {
  margin-top: 57px;
}

.m-t-79 {
  margin-top: 79px;
}

.centralize {
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.m-centralize {
  margin: 0 auto;
}

.h-480 {
  height: 380px;
}

.w-300 {
  width: 300px;
}

.w-25 {
  width: 25px;
}

.w-32 {
  width: 32px;
}

.w-30 {
  width: 30px;
}

.w-45 {
  width: 45px;
}

.w-100P {
  max-width: 250px;
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

.w-100p {
  width: 100% !important;
}

.w-350P {
  max-width: 350px;
  width: 100%;
}

.w-300P {
  max-width: 300px;
  width: 100%;
}

.w-500P {
  max-width: 500px;
  width: 100%;
}

.m-middle {
  margin: 0 auto;
}

.al {
  text-align: left !important;
}

.f-s-20 {
  font-size: 20px;
}

.f-s-22 {
  font-size: 22px !important;
}

.f-s-24 {
  font-size: 24px !important;
}

.f-s-18 {
  font-size: 18px;
}

.f-s-8 {
  font-size: 8px;
}

.f-w-600 {
  font-weight: 600;
}

.f-s-15 {
  font-size: 15px;
}

.f-s-13 {
  font-size: 13px;
}

.f-s-14 {
  font-size: 14px;
}

.f-s-10 {
  font-size: 10px;
}

.f-w-500 {
  font-weight: 500;
}

.l-h-18 {
  line-height: 18px;
}

.l-h-10 {
  line-height: 10px;
}

.l-h-15 {
  line-height: 15px;
}

.h-100p {
  height: 100%;
}

.l-s {
  letter-spacing: 0.0115367em;
}

.m-b-24 {
  margin-bottom: 24px;
}

.pos-t-42 {
  top: 42px;
}

.p-t-37 {
  padding-top: 37px;
}

.p-t-33 {
  padding-top: 33px;
}

.p-t-45 {
  padding-top: 45px;
}

.p-t-153 {
  padding-top: 153px;
}

.p-t-46 {
  padding-top: 46px;
}

.p-t-50 {
  padding-top: 50px;
}

.p-t-40 {
  padding-top: 40px;
}

.p-t-43 {
  padding-top: 43px;
}

.m-t-66 {
  margin-top: 66px;
}

.m-t-65 {
  margin-top: 65px;
}

.m-t-62 {
  margin-top: 62px;
}

.m-t-68 {
  margin-top: 68px;
}

.m-t-64 {
  margin-top: 64px;
}

.m-t-72 {
  margin-top: 72px;
}

.m-t-75 {
  margin-top: 75px;
}

.p-t-76 {
  padding-top: 76px;
}

.p-t-70 {
  padding-top: 70px;
}

.p-t-168 {
  padding-top: 168px;
}

.p-l-112 {
  padding-left: 112px;
}

.p-r-112 {
  padding-right: 112px;
}

.p-l-24 {
  padding-left: 24px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-24 {
  padding: 24px;
}

.p-30 {
  padding: 30px;
}

.p-32 {
  padding: 32px;
}

.p-40 {
  padding: 40px !important;
}

.p-l-32 {
  padding-left: 32px;
}

.p-l-34 {
  padding-left: 34px;
}

.p-r-32 {
  padding-right: 32px;
}

.p-l-60 {
  padding-left: 60px;
}

.p-r-24 {
  padding-right: 24px;
}

.p-r-8 {
  padding-right: 8px;
}

.p-r-4 {
  padding-right: 4px;
}

.p-8 {
  padding: 8px;
}

.p-l-8 {
  padding-left: 8px;
}

.p-r-16 {
  padding-right: 16px;
}

.p-l-16 {
  padding-left: 16px;
}

.p-b-14 {
  padding-bottom: 14px;
}

.p-b-16 {
  padding-bottom: 16px;
}

.p-b-17 {
  padding-bottom: 17px;
}

.p-b-24 {
  padding-bottom: 24px;
}

.p-b-23 {
  padding-bottom: 23px;
}

.p-b-29 {
  padding-bottom: 29px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-b-32 {
  padding-bottom: 32px;
}

.p-b-71 {
  padding-bottom: 71px;
}

.p-b-72 {
  padding-bottom: 72px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-b-45 {
  padding-bottom: 45px;
}

.p-b-49 {
  padding-bottom: 49px;
}

.p-t-24 {
  padding-top: 24px !important;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-14 {
  padding-top: 14px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-b-31 {
  padding-bottom: 31px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-2 {
  margin-bottom: 2px;
}

.m-b-4 {
  margin-bottom: 4px;
}

.m-b-6 {
  margin-bottom: 6px;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-b-16 {
  margin-bottom: 16px;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-43 {
  margin-bottom: 43px;
}

.m-b-53 {
  margin-bottom: 53px;
}

.m-l-0 {
  margin-left: 0;
}

.m-l-24 {
  margin-left: 24px;
}

.m-l-25 {
  margin-left: 25px;
}

.m-l-27 {
  margin-left: 27px;
}

.m-l-7 {
  margin-left: 7px;
}

.m-l-69 {
  margin-left: 69px;
}

.plain-back-button {
  cursor: pointer;
  padding: 16px;
  height: 56px;
  background: rgba(3, 87, 238, 0.1);
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #0361f0;
  mix-blend-mode: normal;
}

.plain-button {
  display: flex;
  height: 48px;
  padding: 6px 16px;
  gap: 4px;
  border-radius: 8px;
  cursor: pointer;
  justify-content: center;
  background: #E6EEFD;
  width: 100%;
  color:  #0361F0;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 124%;
  text-transform: capitalize;
}

.btn-onboarding {
  background: rgba(3, 87, 238, 0.1);
  color: #0361f0;
  line-height: 14px;
  text-align: center;
}

.m-l-65 {
  margin-left: 65px;
}

.m-l-60 {
  margin-left: 60px;
}

.m-l-76 {
  margin-left: 76px;
}

.m-l-55 {
  margin-left: 55px;
}

.m-l-6 {
  margin-left: 6px;
}

.m-l-40 {
  margin-left: 40px;
}

.m-l-48 {
  margin-left: 48px;
}

.m-l-47 {
  margin-left: 47px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-12 {
  margin-left: 12px;
}

.m-l-15 {
  margin-left: 15px;
}

.web-view-back-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  mix-blend-mode: normal;
  cursor: pointer;
}

.m-l-11 {
  margin-left: 11px;
}

.m-l-12 {
  margin-left: 12px;
}

.m-l-13 {
  margin-left: 13px;
}

.m-l-8 {
  margin-left: 8px;
}

.m-l-14 {
  margin-left: 14px;
}

.m-l-16 {
  margin-left: 16px;
}

.m-l-17 {
  margin-left: 17px;
}

.m-l-19 {
  margin-left: 19px;
}

.m-l-18 {
  margin-left: 18px;
}

.m-l-166 {
  margin-left: 166px;
}

.m-l-9 {
  margin-left: 9px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-11 {
  margin-right: 11px;
}

.m-r-15 {
  margin-right: 15px;
}

.add-fill {
  color: #0361f0 !important;
}

.m-r-6 {
  margin-right: 6px;
}

.m-r-16 {
  margin-right: 16px;
}

.m-l-2 {
  margin-left: 2px;
}

.m-l-4 {
  margin-left: 4px;
}

.m-r-4 {
  margin-right: 4px;
}

.m-r-24 {
  margin-right: 24px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-l-37 {
  margin-left: 37px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-r-2 {
  margin-right: 2px;
}

.m-r-36 {
  margin-right: 36px;
}

.m-r-28 {
  margin-right: 28px;
}

// .text-center {
// 	text-align: center !important;
// }

.fs-13 {
  font-size: 13px;
}

.full-width {
  width: 100%;
}

.reduce-width {
  min-width: 120px !important;
}

.reduce-width-btn button {
  min-width: 120px !important;
}

.see-more {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin: 2em;
  line-height: 15px;
  letter-spacing: 0.0126316em;
  color: #0361f0;
  mix-blend-mode: normal;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right;
}

.p-3 {
  padding: 10px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.pos-relative {
  position: relative;
}

.pos-top-8 {
  top: 8px;
}

.pos-top-84 {
  top: 84px;
}

.pos-top-np8em {
  top: -0.8em;
}

.text-bold {
  font-weight: 500 !important;
}

.total-fee {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.000909091em;
  color: #000000;
  mix-blend-mode: normal;
}

.text-bolder {
  font-weight: 600 !important;
}

.color_white {
  color: #ffffff !important;
}

.rate_red {
  color: #e94444;
  font-weight: 500 !important;
}

.row_color_red {
  background: rgba(233, 68, 68, 0.17) !important;
}

.rate_orange {
  color: #f08922;
  font-weight: 500 !important;
}

.row_color_orange {
  background: rgba(240, 137, 34, 0.1) !important;
}

.rate_green {
  color: #1eb12d;
  font-weight: 500 !important;
}

.row_color_green {
  background: rgba(30, 177, 45, 0.1) !important;
}

.dark-text {
  color: #1a0c2f;
}

.addCursor {
  cursor: pointer;
}

@media screen and (max-height: 640px) {
  .add-bottom-padding {
    @apply pb-[32px];
  }
}

.dot-seperator {
  width: 5px;
  display: inline-block;
  height: 5px;
  background: #c4c4c4;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 8px;
  border: 3px solid #c4c4c4;
}

.text-error {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #e94444;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px 0px;
}

.empty-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.text-warning {
  color: orange;
}

.text-primary {
  color: #0361f0;
}

.fw-bold {
  font-weight: bold;
}

.text-white {
  color: #ffffff;
}

.bare-button {
  background: none;
  outline: none;
  border: none;
}

.text-sm {
  font-size: 0.8rem;
}

.text-md {
  font-size: 1.4rem;
}

.text-lg {
  font-size: 1.5rem;
}

.mb-0 {
  margin-bottom: 0;
}

.text-grey {
  color: rgba(0, 0, 0, 0.5);
}

.text-black {
  color: #000000;
}

.text-success {
  color: #07b442;
}

.disable-link-color {
  color: #063a4f;
}

.disable-action-link {
  font-style: normal;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  /* Credit */
  color: #dc3545;
}

.enable-action-link {
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  font-size: 13px;
  line-height: 15px;
  /* Credit */
  color: #0361f0;
}

.break-word {
  overflow-wrap: break-word;
}

.dot-seperator {
  width: 5px;
  display: inline-block;
  height: 5px;
  background: #c4c4c4;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 8px;
  border: 3px solid #c4c4c4;
}

.account-details-container {
  margin-top: 2px;
  display: inline;
}

.account-number {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0126316em;
  color: #0361f0;
  mix-blend-mode: normal;
  margin-right: 3px;
}

.modal-account-details {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.0126316em;
  color: #0a2240;
  mix-blend-mode: normal;
}

//noinspection ALL
.short-text-msg {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.0126316em;
  color: $primary-text-color;
  mix-blend-mode: normal;
}

.dashboard-error-text {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  cursor: pointer;
  line-height: 19px;
  color: #d45866;
  letter-spacing: 0.0126316em;
  mix-blend-mode: normal;
}

.colored-grey-container {
  height: 54px;
  max-width: 600px;
  position: relative;
  color: #0361f0 !important;
  //margin-right: -10%;
  margin-left: -1.68em;
  margin-right: -1.68em;
  padding-left: 25px;
  padding-top: 10px;
  background: rgba(3, 97, 240, 0.09);
}

.close-dialog-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  line-height: 24px;
  color: #0361f0;
  mix-blend-mode: normal;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}

.colored-soft-container {
  height: 54px;
  width: 100%;
  //position: relative;
  //top:1em;
  margin-top: 3em;
  padding-left: 10px;
  padding-top: 10px;
  background: #f2f7fe; //#1890ff
  //background: red;
}

.colored-red-container {
  height: 66px;
  width: 496px;
  position: relative;
  left: 0px;
  background-color: rgba(255, 0, 0, 0.05);
  padding-left: 24px;
  padding-top: 10px;
}

.colored-green-container {
  min-height: 66px;
  width: 496px;
  position: relative;
  left: 0;
  background-color: rgba(7, 180, 66, 0.05);
  padding-left: 24px;
  padding-top: 10px;
  padding-bottom: 8px;
}

.timer {
  .hrs,
  .mins,
  .secs {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #0361f0;
  }

  .seperator {
    color: rgba(11, 50, 117, 0.3);
    font-weight: 1000;
    font-size: 16px;
    line-height: 19px;
    padding: 1px;
  }

  .name {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    padding-left: 2px;
  }
}

@media screen and (max-width: 768px) {
  //.colored-grey-container {
  //  width: 100%;
  //  left: 0 !important;
  //  padding-left: 5px;
  //}

  .page-title {
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.0126316em;
  }
}

//noinspection ALL
.overwrite-color {
  color: $primary-text-color !important;
}

.overwrite-modal-text {
  color: #5d7f8d !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 20px;
}

.input-search {
  //min-width: 367px;
  border-top-left-radius: 5px;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 0px !important;
  height: 48px !important;
}

.card-form-container {
  border-radius: 16px;
  height: 100%;
  padding: 20px 8%;
}

.card-form-container-mobile {
  border-radius: 16px;
  height: 100%;
  padding: 20px 24px;
}

.card-form-bg {
  background-color: rgba(11, 50, 117, 0.02);
}

.create-business-form-general-title {
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 23px;
  color: #000000;
}

.create-business-form-general-sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

@media screen and (max-width: 928px) {
  .input-search {
    //min-width: 355px;
  }
}

@media screen and (max-width: 920px) {
  .input-search {
    //min-width: 328px;
  }
}

@media screen and (max-width: 886px) {
  .input-search {
    //min-width: 264px;
  }
}

@media screen and (max-width: 824px) {
  .input-search {
    //min-width: 234px;
  }
}

@media screen and (max-width: 798px) {
  .input-search {
    //min-width: 207px;
  }
}

.moniepoint-badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.retrieve-terminal-title {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #063a4f;
  text-align: left;
}

.retrieve-terminal-sub-title {
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #5d7f8d;
}

.retrieve-terminal-main {
  max-width: 416px !important;
  margin: auto;
}

.centralize-text {
  display: flex;
  justify-content: left;
  align-items: center;
  vertical-align: middle;
}

.utility-main {
  max-width: 816px !important;
  margin: auto;
}

.retrieve-terminal-confirm-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-top: 71px;
  /* identical to box height */
  color: #063a4f;
}

.confirm-dialog-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #063a4f;
}

.confirm-dialog-sub-text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* or 167% */
  color: #063a4f;
}

.retrieve-terminal-confirm-note-text {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  margin-top: 16px;
  /* or 154% */
  color: #063a4f;
}

.idCard,
.idCard img {
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
  max-width: 300px;
}

.btn-responsive-width {
  min-width: 125px;
}

.btn-decline {
  min-width: 80px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  background: #e94444 !important;
  border: 1px solid #e94444 !important;
  outline: none !important;
  box-shadow: none !important;
}

.search-input {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 20rem;
  padding-left: 1rem;

  input {
    outline: none;
    border: none;
    margin-left: 1rem;

    &::placeholder {
      color: #000000;
      font-size: 0.8rem;
      opacity: 0.4;
    }
  }
}

.search-input-full {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 1rem;

  input {
    outline: none;
    border: none;
    margin-left: 1rem;

    &::placeholder {
      color: #000000;
      font-size: 0.8rem;
      opacity: 0.4;
    }
  }
}

.text-end {
  text-align: end;
}

.mobile {
  display: none;
}

@media screen and (max-width: 480px) {
  .mobile {
    display: initial;
  }

  .desktop {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .btn-responsive-width {
    min-width: 100px !important;
  }
}

@media screen and (max-width: 420px) {
  .idCard,
  .idCard img {
    max-width: 250px;
  }
}

@media screen and (max-width: 365px) {
  .idCard,
  .idCard img {
    max-width: 240px;
  }
}

.btn-responsive-width i {
  font-size: 24px;
  color: white;
}

.image-container {
  max-width: 416px;
  height: 63px;
  background: #ffffff;
  /* Stroke */
  border: 0.75px solid #e1ecfd;
  padding: 11px 20px;
  border-radius: 5px;
}

.image-title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #0361f0;
}

.image-sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: -0.025em;
  margin-top: -6px;
  color: #2e384d;
  opacity: 0.5;
}

@media screen and (max-width: 420px) {
  .image-sub-title {
    margin-top: -10px !important;
  }
}

.p-l-np4 {
  padding-left: 0.4em;
}

.p-l-np6p5 {
  padding-left: 0.65em;
}

.not-selected-answer {
  border: 1px solid #0361f0;
  padding: 0.5em;
  background-color: #f5f9ff !important;
  border-radius: 4px;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.selected-answer {
  border: 2px solid #0361f0;
  border-radius: 4px;
  background-color: #f5f9ff !important;
  padding: 0.5em;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.selected-answer-label {
  background-color: #0361f0;
  border-radius: 2px;
  width: 25px;
  text-align: center;
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  padding: 0.1em;
  display: block;
}

.not-selected-answer-label {
  border: 1px solid #0361f0;
  border-radius: 2px;
  text-align: center;
  width: 25px;
  color: #063a4f;
  font-size: 15px;
  font-weight: 600;
  padding: 0.1em;
  display: block;
}

.survey-text {
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
}

.question {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #063a4f;
}

.new-business-form {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.9);
}

.button-size-80 {
  min-width: 80px !important;
}

.button-size-182 {
  width: 13rem !important;
}

.button-size-180 {
  width: 12.857142857142858rem !important;
}

.button-size-87 {
  min-width: 87px !important;
}

.button-size-124 {
  min-width: 124px !important;
}

.button-size-193 {
  min-width: 193px !important;
}

.button-size-204 {
  min-width: 204px !important;
}

.button-size-320 {
  min-width: 320px !important;
}

.button-size-335 {
  min-width: 335px !important;
}

.button-size-max-320 {
  max-width: 320px !important;
}

.button-size-max-180 {
  max-width: 180px !important;
}

.button-size-227 {
  min-width: 227px !important;
}

.button-size-236 {
  min-width: 236px !important;
}

.button-size-175 {
  min-width: 175px !important;
}

.button-size-179 {
  min-width: 179px !important;
}

.button-height-57 {
  height: 57px;
}

.button-size-152 {
  min-width: 152px !important;
}

.button-size-151 {
  min-width: 151px !important;
}

.button-size-102 {
  min-width: 102px !important;
}

.button-size-109 {
  min-width: 109px !important;
}

.button-size-157 {
  min-width: 157px !important;
}

.button-size-155 {
  min-width: 155px !important;
}

.business-form-cancel-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #0361f0;
  cursor: pointer;
}

.reload-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  border: 0;
  background: none !important;
  color: #0361f0 !important;
}

.reload-btn i {
  font-size: 1em;
}

.required-field {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #e94444;
}

.required-field-position {
  position: relative;
  left: 0.25em;
  top: 0.1em;
}

.completed-badge {
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  padding: 4px !important;
  color: #1eb12d !important;
  margin-left: 12px;
  line-height: 20px;
  background-color: rgba(30, 177, 45, 0.1);
  border-radius: 4px;
  width: 72px;
  height: 20px;
}

.in-completed-badge {
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  padding: 4px !important;
  color: #f08922 !important;
  margin-left: 12px;
  line-height: 20px;
  background-color: rgba(244, 197, 99, 0.2);
  border-radius: 4px;
  width: 72px;
  height: 20px;
}

.re-do-badge {
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  padding: 4px !important;
  color: #e94444 !important;
  margin-left: 12px;
  line-height: 20px;
  background-color: rgba(233, 68, 68, 0.05);
  border-radius: 4px;
  width: 72px;
  height: 20px;
}

.custom-timeline-process-count-down {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-left: 8px;
  position: relative;
  top: 2px;
  line-height: 20px;
  color: rgba(11, 50, 117, 0.5);
}

.custom-timeline-process-text {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #0361f0;
}

.failed-card-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.failed-card-note {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  opacity: 0.6;
}

.mobile-consolidated-table {
  background: #ffffff;
  margin-top: 8px;
  border: 1px solid rgba(3, 87, 238, 0.05);
  box-sizing: border-box;
  padding: 24px 22px;
  box-shadow: 0px 3px 3px rgba(11, 88, 205, 0.12);
  border-radius: 8px;
}

.mobile-consolidated-table-divider {
  border: 0.7px solid rgba(3, 87, 238, 0.3) !important;
}

.mobile-consolidated-table-divider-container {
  margin-top: -1.2em;
}

.mobile-view-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #0361f0;
}

// change ant dropdown height to match input fields height
.mobile-web-view-background {
  background: rgba(3, 97, 240, 0.09);
  height: 100vh;
}

.gray-out {
  @apply opacity-[0.3];
}

.back-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 24px;
  cursor: pointer;
  //display: flex;
  //align-items: center;
  text-align: center;
  color: #0361f0;
  mix-blend-mode: normal;
  //flex: none;
  //order: 0;
  //flex-grow: 0;
  margin: 0px 8px;
}

.bills-image {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.deactivateTerminal {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #e94444;
  mix-blend-mode: normal;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.full-btn {
  padding: 7px 16px;
  width: 227px;
  height: 56px;
  background: #0361f0 !important;
  border-radius: 4px;
  text-align: center;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff !important;
  mix-blend-mode: normal;
  flex: none;
  flex-grow: 0;
  margin: 0px 8px;
}

.success-divider-adjustment {
  margin-left: -1.6em;
  margin-right: -1.6em;
}

.historical-transaction-btn {
  padding: 11px 0;
  min-height: 36px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  background: none !important;
  border-radius: 41px;
  font-size: 12.5px;
  line-height: 15px;
  color: #0361f0;
  position: relative;
  top: 1px;
  mix-blend-mode: normal;
}

.historical-transaction-btn-lg {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #0361f0;
  mix-blend-mode: normal;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}

.line {
  border: 1px solid rgba(3, 87, 238, 0.1);
  width: 45%;
}

.or-text {
  color: rgba(3, 87, 238, 0.4);
  font-weight: 500;
}

.wizard-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: flex-end;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 0px;
}

.wizard-sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 4px 0px;
}

.vertically-centralized {
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.create-business-modal-v2 {
  .business-name {
    @apply -mt-[0.8em];
  }

  .business-limit {
    @apply -mt-[1.1em];
  }

  @media screen and (max-width: 375px) {
    .business-name {
      @apply -mt-[1.3em];
    }
    .business-limit {
      @apply -mt-[0.9em];
    }
  }
}

.vertically-centralized-30 {
  margin: 0 auto;
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  -ms-transform: translateY(-30%);
  transform: translateY(-30%);
}

.go-back {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0361f0;
  cursor: pointer;
  mix-blend-mode: normal;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}

.icon-divider {
  height: 0px;
  opacity: 0.14;
  border: 0.7px solid #0b3275;
}

.adjust-response-code {
  position: relative;
  left: 1em;
}

#no-border {
  border: none !important;
  background: white !important;
  box-shadow: none;
}

//Fix tailwind overriding switch colour
.ant-switch {
  @apply bg-[#00000040] #{!important};
}

.ant-switch-checked {
  @apply bg-[#1890ff] #{!important};
}

// Kamona switch style override

.kamona-switch {
  .ant-switch {
    @apply bg-[#A9A5AF] min-w-[35px] h-[15.1px] #{!important};

    .ant-switch-handle {
      @apply top-[-3.5px] left-0 w-[22px] h-[22px];

      &::before {
        @apply shadow-[0_1.14286px_3.42857px_#00305726] rounded-full #{!important};
      }
    }
  }

  .ant-switch-checked {
    @apply bg-[#0357ee66] #{!important};

    .ant-switch-handle {
      @apply left-[calc(100%-18px-2px)];

      &::before {
        @apply bg-primary;
      }
    }
  }
}

// custom styles overide
::ng-deep {
  .custom-switch {
    .ant-switch {
      height: 16px;

      &:not(.ant-switch-checked) {
        .ant-switch-handle {
          left: 0;
        }
      }

      .ant-switch-handle {
        top: -3px;
        width: 22px;
        height: 22px;
      }

      &.ant-switch-checked {
        background-color: rgba(3, 87, 238, 0.4);

        .ant-switch-handle {
          &::before {
            background-color: #0361f0;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.carousel-controls {
  position: absolute !important;
  z-index: 950;
}

.carousel-left-control {
  left: -16px;
}

.carousel-right-control {
  right: -15px;
}

.custom-carousel-cover {
  position: relative;
  padding-left: 32px;
}

.custom-carousel-container-wrapper {
  scroll-behavior: smooth;
  overflow-x: auto;
  border-radius: 10px;
}

.scroll-icon-left {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.custom-carousel-container {
  min-height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.custom-row {
  display: flex;
  gap: 24px;
  justify-content: space-between;
}

.custom-col {
  z-index: 500 !important;
  flex-basis: 30%;
  box-sizing: border-box;
  border-radius: 10px;
  min-height: 291px;
  margin-top: 19px;
  background: rgba(0, 0, 0, 0.01);
  position: relative;
}

.no-plan-chosen {
  border: 1px solid #e0e6eb !important;
}

.plan-chosen {
  border: 2px solid #1eb12d !important;
}

.current-selected-label-container {
  margin: 0 auto;
  padding: 5px 16px;
  width: 118px;
  height: 23px;
  z-index: 8000;
  background: #1eb12d;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  top: -1.2em;
}

.carousel-curve-edge {
  position: absolute;
  z-index: 250;
  top: 0;
  right: 0;
  min-height: calc(100% - 1.5em);
  width: 118px;
  background: linear-gradient(
    270deg,
    rgba(0, 46, 130, 0.15) 0%,
    rgba(0, 46, 130, 0) 11.57%
  );
}

.custom-carousel-container > div {
  min-height: 1px;
}

.subtitle-bold-18 {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.2rem;
}

.sign-up-form-content {
  //max-width: 768px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px -2px 14px rgba(53, 113, 205, 0.09);
  border-radius: 16px 16px 0px 0px;
}

.m-t-56 {
  margin-top: 56px;
}

.p-t-27 {
  padding-top: 27px;
}

.custom-mobile-content {
  position: relative;
  height: 100%;

  .form-button {
    position: absolute;
    width: 100%;
    bottom: 0px;
    padding-bottom: 56px;

    .kamona-button-session {
      max-width: 767px;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 786px) {
  .custom-carousel-cover {
    padding-left: 20px;
  }

  .mobile-margin-less {
    background: #fff;
    border-radius: 16px 16px 0 0;
    padding: 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0px -20px -44px;
    min-height: calc(100vh - 165px);
    box-shadow: 0px -2px 14px rgba(53, 113, 205, 0.09);

    .bottom {
      /* display: inline-block;
      height: 100%;
      vertical-align: bottom;
      width: 0px; */
    }
  }

  .custom-floating-input > label {
    padding: 12px 10.5px !important;
  }
}

@include responsive(xs) {
  html {
    font-size: 12px;
  }
}

@include responsive(sm) {
  html {
    font-size: 12px;
  }
}

@include responsive(md) {
  html {
    font-size: 14px;
  }
}

.p-l-25p {
  padding-left: 25%;
}

.p-r-25p {
  padding-right: 25%;
}

.p-l-20p {
  padding-left: 20%;
}

.p-r-20p {
  padding-right: 20%;
}

.top-heading {
  font-size: 24px;
  font-weight: 700;
}

.kyc-desktop-container-wrapper {
  background: $primary-color url('/assets/images/svgs/hero-bg-pattern.svg');
  min-height: 100vh;
  padding-top: 57px;
  padding-bottom: 156px;

  .kyc-desktop-container-logo {
    text-align: center;
    padding-bottom: 77px;
  }
}

.mobile-kamona-container {
  background: #f1f7fe;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .mobile-kamona-header {
    padding: 31.67px 20px 24px;
  }

  .mobile-kamona-header-v2 {
    padding: 31.67px 20px 40px;
  }

  .no-background {
    background: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  .mobile-kamona-content {
    background: #ffffff;
    flex-grow: 1;
    //min-height: calc(100vh - 159.32px);
    box-shadow: 0px -2px 14px rgba(53, 113, 205, 0.09);
    border-radius: 16px 16px 0px 0px;

    .mobile-kamona-inner-content {
      min-height: inherit;
      padding: 24px 20px 32px;
    }
  }

  ::ng-deep .timeline-details .description {
    max-width: 767px !important;
  }
}

.btnsGroup > button.ant-btn.ant-btn-link i svg {
  height: 1.875rem;
  width: auto;
}

@media (max-width: 576px) {
  .btnsGroup > button.ant-btn.ant-btn-primary i svg {
    fill: #0361f0 !important;
    height: 12px;
  }

  .btnsGroup > button.ant-btn.ant-btn-primary i svg > * {
    fill: inherit;
  }
}

.animate-spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bg-blue-secondary {
  background-color: $bg-blue-secondary;
}

.box-shadow-card {
  box-shadow: 0px -2px 22px rgba(0, 83, 208, 0.1);
}

.box-shadow-account-card {
  box-shadow: 0px 1px 1px rgba(6, 73, 175, 0.06);
}

.name-initials-acc {
  background-image: url('../../assets/images/svgs/name-initials-bg.svg');
}

.custom-drawer {
  @apply overflow-x-hidden bg-black/50 h-full z-10 left-0 top-0 right-0 fixed;
}

@layer components {
  .custom-web-form-wrapper {
    @apply bg-gradient-to-r from-[#FBFCFD] from-[50%] to-[#FFFFFF] to-[50%] min-h-[606px] rounded-[16px] py-[48px] flex;
    .header {
      @apply flex flex-col gap-[18px];
      .title {
        @apply leading-[44.64px] text-4xl text-black font-bold;
      }
      .subtitle {
        @apply leading-[22.4px] text-base text-[#666] font-medium;
      }
    }
  }
}

@layer components {
  .custom-web-form-container {
    @apply rounded-2xl bg-white pt-10 pb-12 px-12 min-w-[374px] flex flex-col gap-[24px] [@media(width>=885px)]:min-w-[486px] max-w-[486px];
    .header {
      @apply flex flex-col gap-[8px];
      .title {
        @apply text-[18px] text-[#1A1A1A] font-bold leading-[24.48px];
      }
      .subtitle {
        @apply text-[14px] text-[#4D4D4D] font-normal leading-[19.6px];
      }
    }
  }
}

.kamona-number-counter-container {
  --tw-bg-opacity: 1;
  color: rgb(11 50 117 / var(--tw-bg-opacity));
  @apply font-bold justify-center flex items-center text-center min-w-fit h-6 w-[0.7rem] rounded-md;
}
