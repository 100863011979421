$media: (
	xs: 0,
	ss: 375px, // special kyc-provision-primer-mobile device (Samsung s9,Iphone4)
	sm: 480px,
	md: 768px,
	lg: 1024px,
	xl: 1280px,
);

@mixin responsive($breakpoint) {
	@each $breakpoints, $size in $media {
		@if $breakpoint==$breakpoints {
			@media screen and (min-width: $size) {
				@content;
			}
		}
	}
}
