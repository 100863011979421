
$fontSizesHeaders: (
	h1: 92,
	h2: 60,
	h3: 48,
	h4: 36,
	h5: 24,
	h6: 20
);
$fontSizesSubtitle: (
	sub1: 18,
	sub2: 16,
);
$fontSizesBody: (
	medium: 16,
	regular: 14,
	small: 13
);
$fontSizesOthers: (
	overline1: 16,
	overline2: 14,
	// description: (
	// 	12: 12,
	// 	11: 11
	// )
	description: 12,
);


$typeOfFont: (Headers: $fontSizesHeaders, Subtitle: $fontSizesSubtitle, Body: $fontSizesBody, Others: $fontSizesOthers);


// $fontStyle $fontVariant $fontWeight $fontSize $fontlineHeight $fontFamily
// sample - eg: font: italic bold 500 100% Georgia, serif;
// p{
// 	@include typography(Headers, h1, italic, bold, 500, 100%, "Georgia, serif")
// }

@mixin typography ($typeOfTypography, $fontSelector, $fontStyle, $fontVariant, $fontWeight, $fontlineHeight, $fontFamily) {
	
	@each $fontType, $value in $typeOfFont {
		@if $fontType==$typeOfTypography {
			@each $selector, $size in $value {
				@if $selector == $fontSelector {
					font: $fontStyle $fontVariant $fontWeight $size $fontlineHeight unquote($fontFamily);
				}
			}
		}
	}
}




/* kamona typography styles */

/****---------fontsize for headers (based on 14px base size) ------------------*/

.kamona-header-1-fs{
	// font-size: 92px;
	font-size: 6.571em;
}
.kamona-header-2-fs{
	// font-size: 60px;
	font-size: 4.286em;
}
.kamona-header-3-fs{
	// font-size: 48px;
	font-size: 3.429em;
}
.kamona-header-4-fs{
	// font-size: 36px;
	font-size: 2.571em;
}
.kamona-header-5-fs{
	// font-size: 24px;
	font-size: 1.714em;
}
.kamona-header-6-fs{
	// font-size: 20px;
	font-size: 1.429em;
}

/***---------font-weight for subtitles --------------------*/

.kamona-subtitle-1-fs {
	// font-size: 18px;
	font-size: 1.248em;
}

.kamona-subtitle-2-fs {
	// font-size: 16px;
	font-size: 1.143rem;
}