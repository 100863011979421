/* You can add global styles to this file, and also import other style files */
@import 'assets/styles/main';
@import '@loom/mfa/styles';
@import 'animate.css/animate.min';
@import 'video.js/dist/video-js.css';
@import 'videojs-record/dist/css/videojs.record.css';
@import 'swiper/css/bundle';
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import "@angular/cdk/overlay-prebuilt.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	-webkit-text-size-adjust: none;
	touch-action: manipulation;
}


.pac-logo:after {
	background-image: none !important;
}

div {
	transition: all ease;
}

:root {
	--animate-delay: 0.5s;
}

:root {
	--animate-duration: 0.5s;
}


.dot-flashing {
	position: relative;
	width: 20px;
	height: 20px;
	border-radius: 10px;
	background-color: #0361F0;
	color: #0361F0;
	animation: dotFlashing 1s infinite linear alternate;
	animation-delay: .5s;
}

.dot-flashing::before,
.dot-flashing::after {
	content: '';
	display: inline-block;
	position: absolute;
	top: 0;
}

.dot-flashing::before {
	left: -32.5px;
	width: 20px;
	height: 20px;
	border-radius: 10px;
	background-color: #0361F0;
	color: #0361F0;
	animation: dotFlashing 1s infinite alternate;
	animation-delay: 0s;
}

.dot-flashing::after {
	left: 32.5px;
	width: 20px;
	height: 20px;
	border-radius: 10px;
	background-color: #0361F0;
	color: #0361F0;
	animation: dotFlashing 1s infinite alternate;
	animation-delay: 1s;
}

@keyframes dotFlashing {
	0% {
		background-color: #0361F0;
	}

	50%,
	100% {
		background-color: #ebe6ff;
	}
}

@media (max-width: 577px) {
	.group-3130 .ant-progress .ant-progress-inner {
		height: 100px !important;
		width: 100px !important;
	}
}

.pointer {
	cursor: pointer;
}


.ovl {
	overflow: overlay;
}

.scrolling {
	--scrollbar-color: #00000040 !important;
}

.border-bottom {
	border-bottom: 1px solid #b2acac;
}

.border-E5E5E5 {
	border-color: #E5E5E5 !important;
}
