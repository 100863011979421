@import 'src/assets/styles/utils/variables';

.ant-tabs-nav-list {
	.ant-tabs-tab {
		justify-content: center;

		.tab {
			margin-right: auto !important;
			margin-left: auto !important;
		}
	}
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: $primary-color;
}

.ant-tabs {
	color: $primary-text-color;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
	margin-right: 8px;
}

.ant-tabs-card {
	.ant-tabs-nav {
		margin: 0;

		.ant-tabs-tab {
			background: rgba(3, 87, 238, 0.05);
			padding: 23.5px 16px;
			border-radius: 16px 16px 0 0 !important;
			border: none;
			color: rgba(11, 50, 117, 0.7);
		}

		.ant-tabs-tab-active {
			box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
			background: white;
		}

		font-weight: 500;
	}

	.ant-tabs-content-holder {
		background: white;
		border-radius: 0 16px 16px 16px;
	}

	&.ant-tabs-top {
		.ant-tabs-nav::before {
			border-bottom: none;
		}
	}
}

nz-tabset.transactions-large-tab {
	nz-tabs-nav::before {
		border-bottom: none !important;
	}

	.ant-tabs-tab {
		a {
			color: transparentize($dark-blue-text, 0.3);
		}

		&.ant-tabs-tab-active {
			a {
				color: $primary-color;

				.unreconciled-badge {
					background: $primary-color  !important;
				}
			}
		}
	}
}

nz-tabset.pill-tabs {
	nz-tabs-nav::before {
		border-bottom: none !important;
	}

	.ant-tabs>.ant-tabs-nav .ant-tabs-nav-more {
		height: 16px;
	}

	.ant-tabs-tab {
		padding: 5px 12px !important;
		height: 29px;
		border-radius: 29px;
		background-color: white;
		box-shadow: 0px 1px 2px rgba(3, 97, 240, 0.1);
		font-size: 12px;
		font-weight: 600;
		line-height: 16px;
		letter-spacing: 0em;
		text-align: center;
		color: rgba(11, 50, 117, 0.7);

		.ant-tabs-tab-btn {
			display: flex;
			gap: 6px;
			align-items: center;

			.tab-count {
				padding: 3px 6px;
				border-radius: 4.42px;
				background: rgba(11, 50, 117, 0.7);
				color: white;
				font-size: 11px;
				font-weight: 800;
				line-height: 13px;
				letter-spacing: 0em;
				text-align: center;
			}
		}

		&.ant-tabs-tab-active {
			background: $primary-color;

			.ant-tabs-tab-btn {
				color: white;
			}
		}
	}

	.ant-tabs-tab+.ant-tabs-tab {
		margin: 0 0 0 8px;
	}

	.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
		display: none;
	}

}

nz-tabset.card-tabs {
	.ant-tabs-tab {
		.ant-tabs-tab-btn {
			display: flex;
			gap: 8px;
			align-items: center;

			.tab-count {
				padding: 3px 6px;
				border-radius: 4.42px;
				background: $primary-color;
				color: white;
				font-size: 11px;
				font-weight: 700;
				line-height: 13px;
				letter-spacing: 0em;
				text-align: center;
				display: none;
			}
		}

		&.ant-tabs-tab-active {
			.ant-tabs-tab-btn {
				.tab-count {
					background: $primary-color;
					display: inline-flex;
				}
			}
		}
	}
}