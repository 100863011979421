@import "src/assets/styles/utils/variables";

.cancel-subscription-thumbnail {
	&[nz-icon] {
		svg {
			stroke: #FFF;
			stroke-width: 25;
		}
	}
}

.unreconciled-transaction-icon {
	&[nz-icon] {
		svg {
			stroke: $primary-color;
			stroke-width: 25;
		}
	}
}

.reports-thumbnail {
	@extend .cancel-subscription-thumbnail;

	svg {
		stroke: $primary-color;
	}
}
