.ant-table-thead {
	tr {
		th {
			background: white;
			color: #5D7F8D;
			font-size: 13px;
			font-weight: normal;
		}
	}
}

.ant-table {
	font-size: 13px;
	font-size: 13px;
	margin: auto 32px;
	//   box-shadow: 0px 2px 3px rgb(13 79 100 / 12%);
	border-radius: 5px;
	//   border: 0.5px solid rgba(0, 0, 0, 0.1);
}

.ant-table-pagination.ant-pagination {
	margin: 16px 15px;
}

.ant-table-cell {
	padding: 21px 0 16px 0 !important;
}

.ant-table-tbody {
	.ant-table-row {
		cursor: pointer;
	}
}

.mobile-table {
	margin-top: 10px;
	padding-top: 24px;
	padding-left: 27px;
	padding-bottom: 16px;
	width: 100%;
	font-size: 13px;
	letter-spacing: 0.0126316em;

	.ant-row {
		> div {
			margin-bottom: 4px;
		}
	}
}

table {
	.row-icon-space {
		width: 70px
	}
}

.table-header-section {
	padding: 0 32px;

	> section {
		border-bottom: 1px solid rgba(155, 166, 185, 0.2);
		padding-bottom: 10px;
	}

	.section-title {
		font-size: 24px;
		padding: 8px 0;
		font-weight: bold;
	}
}

.billings-table {
	.ant-table {
		margin: auto 0;

		table {
			border-collapse: collapse;
		}

		tr {
			border-radius: 10px;

			&:nth-child(2n+1) {
				background: rgba(3, 87, 238, 0.03);
			}

			td {
				padding-right: 16px !important;
				padding-left: 16px !important;
				border: none;

				&:first-child {
					border-radius: 10px 0 0 10px;
				}

				&:last-child {
					border-radius: 0 10px 10px 0;
				}
			}
		}

	}
}

.reconciled-transactions-table {
	.ant-table {
		margin: 0;

		.ant-table-cell:before {
			display: none;
		}
	}
}
