.bookkeeping-swiper {
	.swiper-slide-prev, .swiper-slide-next {
		transform: scale(0.93) !important;
		transition: transform 0.2s linear;
	}

	.swiper-slide-active {
		transform: scale(1) !important;
	}
}
