#terms-and-condition-content {

    :host {
        .profile-web-form-wrapper {
            .bg-default-color {
                @apply bg-gradient-to-r from-[#EBEDF1] from-[55%] to-[#F5F5F5] to-[45%];
            }

            .bg-color {
                @apply bg-[#F5F5F5];
            }

            .bg-properties {
                @apply px-[20px] flex justify-center min-h-screen;
            }

            .header {
                @apply flex flex-col gap-[18px];

                .title {
                    @apply leading-[44.64px] text-4xl text-black font-bold;
                }

                .subtitle {
                    @apply leading-[22.4px] text-base text-[#666] font-medium;
                }
            }
        }
    }

    .lst-kix_list_4-1>li {
        counter-increment: lst-ctn-kix_list_4-1
    }

    ol.lst-kix_list_3-1 {
        list-style-type: none
    }

    ol.lst-kix_list_3-2 {
        list-style-type: none
    }

    .lst-kix_list_3-1>li {
        counter-increment: lst-ctn-kix_list_3-1
    }

    ol.lst-kix_list_3-3 {
        list-style-type: none
    }

    ol.lst-kix_list_3-4.start {
        counter-reset: lst-ctn-kix_list_3-4 0
    }

    ol.lst-kix_list_3-4 {
        list-style-type: none
    }

    .lst-kix_list_2-1>li {
        counter-increment: lst-ctn-kix_list_2-1
    }

    ol.lst-kix_list_3-0 {
        list-style-type: none
    }

    .lst-kix_list_1-1>li {
        counter-increment: lst-ctn-kix_list_1-1
    }

    ol.lst-kix_list_2-6.start {
        counter-reset: lst-ctn-kix_list_2-6 0
    }

    .lst-kix_list_3-0>li:before {
        content: "" counter(lst-ctn-kix_list_3-0, decimal) ". "
    }

    ol.lst-kix_list_3-1.start {
        counter-reset: lst-ctn-kix_list_3-1 0
    }

    .lst-kix_list_3-1>li:before {
        content: "" counter(lst-ctn-kix_list_3-1, lower-latin) " "
    }

    .lst-kix_list_3-2>li:before {
        content: "" counter(lst-ctn-kix_list_3-2, lower-roman) " "
    }

    ol.lst-kix_list_1-8.start {
        counter-reset: lst-ctn-kix_list_1-8 0
    }

    .lst-kix_list_4-0>li {
        counter-increment: lst-ctn-kix_list_4-0
    }

    ol.lst-kix_list_2-3.start {
        counter-reset: lst-ctn-kix_list_2-3 0
    }

    .lst-kix_list_3-5>li:before {
        content: "" counter(lst-ctn-kix_list_3-5, lower-roman) " "
    }

    .lst-kix_list_3-4>li:before {
        content: "" counter(lst-ctn-kix_list_3-4, lower-latin) " "
    }

    ol.lst-kix_list_1-5.start {
        counter-reset: lst-ctn-kix_list_1-5 0
    }

    .lst-kix_list_3-3>li:before {
        content: "" counter(lst-ctn-kix_list_3-3, decimal) " "
    }

    ol.lst-kix_list_3-5 {
        list-style-type: none
    }

    ol.lst-kix_list_3-6 {
        list-style-type: none
    }

    ol.lst-kix_list_3-7 {
        list-style-type: none
    }

    ol.lst-kix_list_3-8 {
        list-style-type: none
    }

    li.li-bullet-44:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt
    }

    li.li-bullet-35:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt
    }

    li.li-bullet-37:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt
    }

    .lst-kix_list_3-8>li:before {
        content: "" counter(lst-ctn-kix_list_3-8, lower-roman) " "
    }

    li.li-bullet-46:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt
    }

    li.li-bullet-42:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt
    }

    .lst-kix_list_2-0>li {
        counter-increment: lst-ctn-kix_list_2-0
    }

    .lst-kix_list_2-3>li {
        counter-increment: lst-ctn-kix_list_2-3
    }

    li.li-bullet-39:before {
        margin-left: -28.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 28.4pt
    }

    li.li-bullet-33:before {
        margin-left: -54pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 54pt
    }

    .lst-kix_list_3-6>li:before {
        content: "" counter(lst-ctn-kix_list_3-6, decimal) " "
    }

    li.li-bullet-31:before {
        margin-left: -54pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 54pt
    }

    .lst-kix_list_4-3>li {
        counter-increment: lst-ctn-kix_list_4-3
    }

    .lst-kix_list_3-7>li:before {
        content: "" counter(lst-ctn-kix_list_3-7, lower-latin) " "
    }

    ol.lst-kix_list_4-5.start {
        counter-reset: lst-ctn-kix_list_4-5 0
    }

    .lst-kix_list_1-2>li {
        counter-increment: lst-ctn-kix_list_1-2
    }

    ol.lst-kix_list_3-7.start {
        counter-reset: lst-ctn-kix_list_3-7 0
    }

    li.li-bullet-40:before {
        margin-left: -28.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 28.4pt
    }

    ol.lst-kix_list_4-2.start {
        counter-reset: lst-ctn-kix_list_4-2 0
    }

    .lst-kix_list_3-2>li {
        counter-increment: lst-ctn-kix_list_3-2
    }

    ol.lst-kix_list_2-2 {
        list-style-type: none
    }

    ol.lst-kix_list_2-3 {
        list-style-type: none
    }

    ol.lst-kix_list_2-4 {
        list-style-type: none
    }

    ol.lst-kix_list_2-5 {
        list-style-type: none
    }

    .lst-kix_list_1-4>li {
        counter-increment: lst-ctn-kix_list_1-4
    }

    .lst-kix_list_4-4>li {
        counter-increment: lst-ctn-kix_list_4-4
    }

    ol.lst-kix_list_2-0 {
        list-style-type: none
    }

    ol.lst-kix_list_1-6.start {
        counter-reset: lst-ctn-kix_list_1-6 0
    }

    ol.lst-kix_list_2-1 {
        list-style-type: none
    }

    .lst-kix_list_4-8>li:before {
        content: "" counter(lst-ctn-kix_list_4-8, lower-roman) " "
    }

    .lst-kix_list_4-7>li:before {
        content: "" counter(lst-ctn-kix_list_4-7, lower-latin) " "
    }

    li.li-bullet-6:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt
    }

    li.li-bullet-7:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt
    }

    ol.lst-kix_list_4-1.start {
        counter-reset: lst-ctn-kix_list_4-1 0
    }

    li.li-bullet-10:before {
        margin-left: -33.3pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 33.3pt
    }

    li.li-bullet-13:before {
        margin-left: -42.5pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 42.5pt
    }

    li.li-bullet-14:before {
        margin-left: -40.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 40.4pt
    }

    ol.lst-kix_list_4-8.start {
        counter-reset: lst-ctn-kix_list_4-8 0
    }

    ol.lst-kix_list_3-3.start {
        counter-reset: lst-ctn-kix_list_3-3 0
    }

    ol.lst-kix_list_2-6 {
        list-style-type: none
    }

    ol.lst-kix_list_2-7 {
        list-style-type: none
    }

    ol.lst-kix_list_2-8 {
        list-style-type: none
    }

    li.li-bullet-36:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt
    }

    li.li-bullet-18:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    ol.lst-kix_list_1-0.start {
        counter-reset: lst-ctn-kix_list_1-0 0
    }

    li.li-bullet-43:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    .lst-kix_list_3-0>li {
        counter-increment: lst-ctn-kix_list_3-0
    }

    .lst-kix_list_3-3>li {
        counter-increment: lst-ctn-kix_list_3-3
    }

    li.li-bullet-32:before {
        margin-left: -54pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 54pt
    }

    ol.lst-kix_list_4-0.start {
        counter-reset: lst-ctn-kix_list_4-0 0
    }

    .lst-kix_list_3-6>li {
        counter-increment: lst-ctn-kix_list_3-6
    }

    li.li-bullet-2:before {
        margin-left: -28.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 28.4pt
    }

    li.li-bullet-21:before {
        margin-left: -28.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 28.4pt
    }

    li.li-bullet-25:before {
        margin-left: -36.5pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36.5pt
    }

    li.li-bullet-29:before {
        margin-left: -54pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 54pt
    }

    .lst-kix_list_2-5>li {
        counter-increment: lst-ctn-kix_list_2-5
    }

    .lst-kix_list_2-8>li {
        counter-increment: lst-ctn-kix_list_2-8
    }

    ol.lst-kix_list_3-2.start {
        counter-reset: lst-ctn-kix_list_3-2 0
    }

    .lst-kix_list_2-2>li {
        counter-increment: lst-ctn-kix_list_2-2
    }

    ol.lst-kix_list_2-4.start {
        counter-reset: lst-ctn-kix_list_2-4 0
    }

    ol.lst-kix_list_4-7.start {
        counter-reset: lst-ctn-kix_list_4-7 0
    }

    ol.lst-kix_list_1-3 {
        list-style-type: none
    }

    ol.lst-kix_list_1-4 {
        list-style-type: none
    }

    .lst-kix_list_2-6>li:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) "." counter(lst-ctn-kix_list_2-3, decimal) "." counter(lst-ctn-kix_list_2-4, decimal) "." counter(lst-ctn-kix_list_2-5, decimal) "." counter(lst-ctn-kix_list_2-6, decimal) ". "
    }

    .lst-kix_list_2-7>li:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) "." counter(lst-ctn-kix_list_2-3, decimal) "." counter(lst-ctn-kix_list_2-4, decimal) "." counter(lst-ctn-kix_list_2-5, decimal) "." counter(lst-ctn-kix_list_2-6, decimal) "." counter(lst-ctn-kix_list_2-7, decimal) ". "
    }

    .lst-kix_list_2-7>li {
        counter-increment: lst-ctn-kix_list_2-7
    }

    .lst-kix_list_3-7>li {
        counter-increment: lst-ctn-kix_list_3-7
    }

    ol.lst-kix_list_1-5 {
        list-style-type: none
    }

    ol.lst-kix_list_1-6 {
        list-style-type: none
    }

    ol.lst-kix_list_1-0 {
        list-style-type: none
    }

    .lst-kix_list_2-4>li:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) "." counter(lst-ctn-kix_list_2-3, decimal) "." counter(lst-ctn-kix_list_2-4, decimal) ". "
    }

    .lst-kix_list_2-5>li:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) "." counter(lst-ctn-kix_list_2-3, decimal) "." counter(lst-ctn-kix_list_2-4, decimal) "." counter(lst-ctn-kix_list_2-5, decimal) ". "
    }

    .lst-kix_list_2-8>li:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) "." counter(lst-ctn-kix_list_2-3, decimal) "." counter(lst-ctn-kix_list_2-4, decimal) "." counter(lst-ctn-kix_list_2-5, decimal) "." counter(lst-ctn-kix_list_2-6, decimal) "." counter(lst-ctn-kix_list_2-7, decimal) "." counter(lst-ctn-kix_list_2-8, decimal) ". "
    }

    ol.lst-kix_list_1-1 {
        list-style-type: none
    }

    ol.lst-kix_list_1-2 {
        list-style-type: none
    }

    li.li-bullet-20:before {
        margin-left: -28.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 28.4pt
    }

    ol.lst-kix_list_4-6.start {
        counter-reset: lst-ctn-kix_list_4-6 0
    }

    ol.lst-kix_list_3-0.start {
        counter-reset: lst-ctn-kix_list_3-0 0
    }

    ol.lst-kix_list_4-3.start {
        counter-reset: lst-ctn-kix_list_4-3 0
    }

    ol.lst-kix_list_1-7 {
        list-style-type: none
    }

    .lst-kix_list_4-7>li {
        counter-increment: lst-ctn-kix_list_4-7
    }

    .lst-kix_list_1-7>li {
        counter-increment: lst-ctn-kix_list_1-7
    }

    ol.lst-kix_list_1-8 {
        list-style-type: none
    }

    ol.lst-kix_list_3-8.start {
        counter-reset: lst-ctn-kix_list_3-8 0
    }

    li.li-bullet-19:before {
        margin-left: -28.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 28.4pt
    }

    ol.lst-kix_list_2-5.start {
        counter-reset: lst-ctn-kix_list_2-5 0
    }

    li.li-bullet-17:before {
        margin-left: -40.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 40.4pt
    }

    li.li-bullet-1:before {
        margin-left: -21.3pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 21.3pt
    }

    li.li-bullet-24:before {
        margin-left: -36.5pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36.5pt
    }

    .lst-kix_list_4-0>li:before {
        content: "" counter(lst-ctn-kix_list_4-0, lower-latin) ". "
    }

    li.li-bullet-15:before {
        margin-left: -40.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 40.4pt
    }

    .lst-kix_list_2-6>li {
        counter-increment: lst-ctn-kix_list_2-6
    }

    .lst-kix_list_3-8>li {
        counter-increment: lst-ctn-kix_list_3-8
    }

    .lst-kix_list_4-1>li:before {
        content: "" counter(lst-ctn-kix_list_4-1, lower-latin) " "
    }

    li.li-bullet-3:before {
        margin-left: -28.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 28.4pt
    }

    li.li-bullet-22:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    .lst-kix_list_4-6>li {
        counter-increment: lst-ctn-kix_list_4-6
    }

    li.li-bullet-26:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    li.li-bullet-28:before {
        margin-left: -36.5pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36.5pt
    }

    ol.lst-kix_list_1-7.start {
        counter-reset: lst-ctn-kix_list_1-7 0
    }

    .lst-kix_list_4-4>li:before {
        content: "" counter(lst-ctn-kix_list_4-4, lower-latin) " "
    }

    ol.lst-kix_list_2-2.start {
        counter-reset: lst-ctn-kix_list_2-2 0
    }

    .lst-kix_list_1-5>li {
        counter-increment: lst-ctn-kix_list_1-5
    }

    .lst-kix_list_4-3>li:before {
        content: "" counter(lst-ctn-kix_list_4-3, decimal) " "
    }

    .lst-kix_list_4-5>li:before {
        content: "" counter(lst-ctn-kix_list_4-5, lower-roman) " "
    }

    .lst-kix_list_4-2>li:before {
        content: "" counter(lst-ctn-kix_list_4-2, lower-roman) " "
    }

    .lst-kix_list_4-6>li:before {
        content: "" counter(lst-ctn-kix_list_4-6, decimal) " "
    }

    .lst-kix_list_1-8>li {
        counter-increment: lst-ctn-kix_list_1-8
    }

    ol.lst-kix_list_1-4.start {
        counter-reset: lst-ctn-kix_list_1-4 0
    }

    .lst-kix_list_3-5>li {
        counter-increment: lst-ctn-kix_list_3-5
    }

    ol.lst-kix_list_1-1.start {
        counter-reset: lst-ctn-kix_list_1-1 0
    }

    ol.lst-kix_list_4-0 {
        list-style-type: none
    }

    .lst-kix_list_3-4>li {
        counter-increment: lst-ctn-kix_list_3-4
    }

    ol.lst-kix_list_4-1 {
        list-style-type: none
    }

    ol.lst-kix_list_4-4.start {
        counter-reset: lst-ctn-kix_list_4-4 0
    }

    ol.lst-kix_list_4-2 {
        list-style-type: none
    }

    ol.lst-kix_list_4-3 {
        list-style-type: none
    }

    .lst-kix_list_2-4>li {
        counter-increment: lst-ctn-kix_list_2-4
    }

    ol.lst-kix_list_3-6.start {
        counter-reset: lst-ctn-kix_list_3-6 0
    }

    li.li-bullet-8:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt
    }

    li.li-bullet-9:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt
    }

    li.li-bullet-5:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    li.li-bullet-12:before {
        margin-left: -40.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 40.4pt
    }

    ol.lst-kix_list_1-3.start {
        counter-reset: lst-ctn-kix_list_1-3 0
    }

    ol.lst-kix_list_2-8.start {
        counter-reset: lst-ctn-kix_list_2-8 0
    }

    ol.lst-kix_list_1-2.start {
        counter-reset: lst-ctn-kix_list_1-2 0
    }

    li.li-bullet-11:before {
        margin-left: -38.1pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 38.1pt
    }

    ol.lst-kix_list_4-8 {
        list-style-type: none
    }

    .lst-kix_list_1-0>li:before {
        content: "" counter(lst-ctn-kix_list_1-0, lower-roman) ". "
    }

    ol.lst-kix_list_4-4 {
        list-style-type: none
    }

    ol.lst-kix_list_4-5 {
        list-style-type: none
    }

    .lst-kix_list_1-1>li:before {
        content: "" counter(lst-ctn-kix_list_1-1, lower-latin) ". "
    }

    .lst-kix_list_1-2>li:before {
        content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". "
    }

    ol.lst-kix_list_2-0.start {
        counter-reset: lst-ctn-kix_list_2-0 0
    }

    ol.lst-kix_list_4-6 {
        list-style-type: none
    }

    ol.lst-kix_list_4-7 {
        list-style-type: none
    }

    li.li-bullet-45:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt
    }

    .lst-kix_list_1-3>li:before {
        content: "" counter(lst-ctn-kix_list_1-3, decimal) ". "
    }

    .lst-kix_list_1-4>li:before {
        content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". "
    }

    ol.lst-kix_list_3-5.start {
        counter-reset: lst-ctn-kix_list_3-5 0
    }

    li.li-bullet-34:before {
        margin-left: -54pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 54pt
    }

    li.li-bullet-38:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt
    }

    .lst-kix_list_1-0>li {
        counter-increment: lst-ctn-kix_list_1-0
    }

    li.li-bullet-16:before {
        margin-left: -40.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 40.4pt
    }

    .lst-kix_list_4-8>li {
        counter-increment: lst-ctn-kix_list_4-8
    }

    .lst-kix_list_1-6>li {
        counter-increment: lst-ctn-kix_list_1-6
    }

    .lst-kix_list_1-7>li:before {
        content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". "
    }

    ol.lst-kix_list_2-7.start {
        counter-reset: lst-ctn-kix_list_2-7 0
    }

    .lst-kix_list_1-3>li {
        counter-increment: lst-ctn-kix_list_1-3
    }

    .lst-kix_list_1-5>li:before {
        content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". "
    }

    .lst-kix_list_1-6>li:before {
        content: "" counter(lst-ctn-kix_list_1-6, decimal) ". "
    }

    li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    li.li-bullet-4:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    li.li-bullet-23:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    li.li-bullet-27:before {
        margin-left: -36.5pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36.5pt
    }

    li.li-bullet-30:before {
        margin-left: -54pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 54pt
    }

    .lst-kix_list_2-0>li:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) ". "
    }

    .lst-kix_list_2-1>li:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-1, decimal) ". "
    }

    ol.lst-kix_list_2-1.start {
        counter-reset: lst-ctn-kix_list_2-1 0
    }

    .lst-kix_list_4-5>li {
        counter-increment: lst-ctn-kix_list_4-5
    }

    li.li-bullet-41:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    .lst-kix_list_1-8>li:before {
        content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". "
    }

    .lst-kix_list_2-2>li:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) ". "
    }

    .lst-kix_list_2-3>li:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) "." counter(lst-ctn-kix_list_2-3, decimal) ". "
    }

    .lst-kix_list_4-2>li {
        counter-increment: lst-ctn-kix_list_4-2
    }

    ol {
        margin: 0;
        padding: 0
    }

    table td,
    table th {
        padding: 0
    }

    .c15 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 209.1pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c3 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 205.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c17 {
        margin-left: 49.5pt;
        padding-top: 0pt;
        text-indent: -27pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c12 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 12pt
    }

    .c7 {
        margin-left: 72pt;
        padding-top: 0pt;
        padding-left: 18pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c11 {
        margin-left: 72pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 12pt
    }

    .c1 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 12pt
    }

    .c10 {
        margin-left: 72pt;
        padding-top: 0pt;
        padding-left: 20.2pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c9 {
        margin-left: 90pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 12pt
    }

    .c5 {
        margin-left: 39.3pt;
        padding-top: 0pt;
        padding-left: 10.4pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c14 {
        margin-left: 21.3pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 12pt
    }

    .c6 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c2 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal
    }

    .c0 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal
    }

    .c16 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 12pt
    }

    .c19 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c26 {
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Calibri";
        font-style: normal
    }

    .c41 {
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Calibri";
        font-style: normal
    }

    .c23 {
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Times New Roman";
        font-style: normal
    }

    .c30 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c33 {
        -webkit-text-decoration-skip: none;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-style: normal
    }

    .c38 {
        -webkit-text-decoration-skip: none;
        color: #1155cc;
        text-decoration: underline;
        text-decoration-skip-ink: none
    }

    .c18 {
        font-size: 10pt;
        font-family: "Arial";
        font-weight: 700
    }

    .c35 {
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto
    }

    .c8 {
        font-size: 10pt;
        font-family: "Arial";
        font-weight: 400
    }

    .c45 {
        background-color: #ffffff;
        max-width: 451.3pt;
        padding: 72pt 72pt 72pt 72pt
    }

    .c37 {
        margin-left: 67.7pt;
        padding-left: 24.5pt
    }

    .c4 {
        padding: 0;
        margin: 0
    }

    .c36 {
        margin-left: 72pt;
        height: 12pt
    }

    .c28 {
        margin-left: 74.7pt;
        padding-left: 15.3pt
    }

    .c24 {
        margin-left: 67.7pt;
        padding-left: 22.3pt
    }

    .c32 {
        margin-left: 90pt;
        padding-left: 36pt
    }

    .c27 {
        margin-left: 53.5pt;
        padding-left: 18.5pt
    }

    .c34 {
        color: inherit;
        text-decoration: inherit
    }

    .c20 {
        margin-left: 54pt;
        padding-left: 18pt
    }

    .c25 {
        margin-left: 49.6pt
    }

    .c22 {
        margin-left: 36pt
    }

    .c29 {
        margin-left: 18pt
    }

    .c31 {
        margin-left: 126pt
    }

    .c21 {
        color: #000000
    }

    .c13 {
        height: 0pt
    }

    .c42 {
        padding-left: 3.3pt
    }

    .c43 {
        margin-left: 72pt
    }

    .c40 {
        color: #0563c1
    }

    .c44 {
        padding-left: 0pt
    }

    .c39 {
        height: 12pt
    }

    .title {
        padding-top: 0pt;
        color: #000000;
        font-size: 28pt;
        padding-bottom: 0pt;
        font-family: "Calibri";
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1.0;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    li {
        color: #000000;
        font-size: 12pt;
        font-family: "Calibri"
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 12pt;
        font-family: "Calibri"
    }

    h1 {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 24pt;
        padding-bottom: 6pt;
        font-family: "Calibri";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h2 {
        padding-top: 18pt;
        color: #000000;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h3 {
        padding-top: 14pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h4 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        font-family: "Calibri";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h5 {
        padding-top: 11pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        font-family: "Calibri";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h6 {
        padding-top: 10pt;
        color: #000000;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: "Calibri";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .max-width {
        width: 100%;
        max-width: 568pt;
        padding: 16px;
        margin: 0 auto;
    }

    .doc-content {
        margin: 0px;
        display: flex;
        flex-wrap: wrap;
    }

    .c3 {
        padding: 0pt 5.4pt 0pt 5.4pt;
        vertical-align: top;
        width: auto;
        border: none;
    }

    .c15 {
        padding: 0pt 5.4pt 0pt 5.4pt;
        vertical-align: top;
        width: auto;
        border: none;
    }
}