.ant-modal-header {
	text-align: center;
	border-bottom: none;
	border-radius: 16px 16px 0 0;
	padding: 0 24px;

	.ant-modal-title {
		padding-top: 40px;

		img {
			height: 70px;
			position: relative;
			display: none;
			filter: drop-shadow(0px 15.601px 28.8019px rgba(4, 129, 245, 0.26));
		}
	}
}

.ant-modal-body {
	padding: 0 22px 24px 24px;
}

.remove-modal-padding {
	.ant-modal-header {
		padding: 0 !important;

		.ant-modal-title {
			padding: 0 !important;
		}
	}

	.ant-modal-body {
		padding: 0 !important;
	}
}

.hide-ant-modal {
	.ant-modal {
		padding: 0 !important;
		background: transparent !important;
		border: none !important;
	}

	.ant-modal-header {
		padding: 0 !important;
		background: transparent !important;
		border: none !important;

		.ant-modal-title {
			background: transparent !important;
			padding: 0 !important;
			border: none !important;
		}
	}

	.ant-modal-body {
		padding: 0 !important;
		background: transparent !important;
		border: none !important;
	}

	.ant-modal-content {
		box-shadow: none !important;
		background: transparent !important;
	}
}

.hide-ant-modal-only-desktop {
	.ant-modal {
		padding: 0 !important;
		border: none !important;
	}

	.ant-modal-header {
		padding: 0 !important;
		border: none !important;

		.ant-modal-title {
			padding: 0 !important;
			border: none !important;
		}
	}

	.ant-modal-body {
		padding: 0 !important;
		border: none !important;
	}

	.ant-modal-content {
		box-shadow: none !important;
	}
}


.kyc-category-sub-category-modal {
	.ant-modal-header {
		border-radius: 20px 20px 0 0;
	}

	.ant-modal-body {
		padding: 0 40px 40px;
	}

	.ant-modal-content {
		border-radius: 20px;
	}
}

.kamona-modal-alert {
	.ant-modal-header {
		text-align: center;
		border-bottom: none;
		border-radius: 16px 16px 0 0;
		padding: 16px 24px 0;

		.ant-modal-title {
			padding-top: 0;

			img {
				display: none;
			}
		}
	}

	.ant-modal-body {
		padding: 0 0 24px;
	}

	.ant-modal-content {
		box-shadow: 0px 1px 2px rgba(3, 97, 240, 0.1);
		border-radius: 16px;
	}

	.mobile-dialog-body {
		padding: 0 !important;
	}
}

.ant-modal-content {
	box-shadow: 0 2px 3px rgb(13 79 100 / 12%);
	border-radius: 16px;
}

.ant-modal-mask {
	background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(7px);
}

.modal {
	color: #063a4f;

	.title {
		font-size: 20px;
		line-height: 24px;
		font-weight: 500;
	}

	.sub-title {
		font-size: 13px;
		line-height: 15px;
		margin-top: 10px;
	}

	.content-text {
		font-size: 14px;
		line-height: 24px;
	}
}

.ant-modal {
	color: #063a4f;
}

.bonanza-modal .ant-modal-footer {
	display: none !important;
}

@media (max-width: 48em) {
	.ant-modal-body {
		padding: 0 20px 20px 20px;
	}
	.hide-ant-modal-only-desktop {
		.ant-modal {
			padding: 0 !important;
			border: none !important;
		}

		.ant-modal-header {
			padding: 0 !important;
			border: none !important;
			.ant-modal-title {
				padding: 0 !important;
				border: none !important;
			}
		}

		.ant-modal-body {
			padding: 0 !important;
			border: none !important;
		}

		.ant-modal-content {
			box-shadow: none !important;
		}
	}
	.kamona-modal-alert {
		.ant-modal-body {
			padding: 0 0 24px;
		}

		.mobile-dialog-body {
			padding: 16px 20px 32px !important;
		}
	}
}

.subscription-successful-dialog {
	margin: 0;
	max-width: unset;
	padding: 0;
	width: 100vw !important;
	top: 0;

	@extend .subscription-cancellation-reasons-modal
}

.subscription-cancellation-reasons-modal {
	.ant-modal-content {
		width: 100%;

		.ant-modal-header {
			padding: 0;

			.ant-modal-title {
				padding: 0;
			}
		}

		.ant-modal-body {
			padding: 0;
		}
	}
}

.cancel-subscription-dialog {
	.ant-modal-content {
		border-radius: 16px;

		.ant-modal-header {
			border-radius: 16px 16px 0 0;
		}
	}
}

.reports-dialog {
	@extend .cancel-subscription-dialog;

	.ant-modal-content {
		max-width: 405px;
		margin: auto;
	}
}

.reports-large-success-dialog {
	.ant-modal-content {
		width: 318px;
		margin: auto;
		padding: 24px 33px;

		.ant-modal-body {
			padding: 0;
		}

		.ant-modal-title {
			display: none;
		}

		.success-divider {
			margin: 16px 0;
		}

		button {
			width: 100%;
			margin: 0;
			height: 56px;
		}
	}
}

.floating-button-modal {
	@extend .subscription-cancellation-reasons-modal;
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
	left: auto;
	bottom: auto;
	right: auto;
	top: auto;
	min-width: 100vw;

	.ant-modal-content {
		background: transparent;
		box-shadow: none;
		position: absolute;
		bottom: 45px;
		right: 20px;
	}
}

.money-move-modal {
	.ant-modal-content {
		.ant-modal-body {
			padding: 0;
		}

		.ant-modal-header {
			display: none;
		}
	}
}

.reconcile-transaction-modal {
	@extend .money-move-modal;
}

.record-day-balance-modal {
	@extend .money-move-modal;
}

.unreconciled-transaction-detail-modal {
	@extend .money-move-modal;
	width: 496px;
}

.kyc-proof-of-address-modal, .disbursement-loading-modal {
 	@media (min-width: 768px) {
		position: relative;
		height: 100vh;
		width: 100vw !important;
		top: 0;

		.ant-modal-content{
			height: 100%;
		}

		.ant-modal-body{
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.account-statement-modal {
	width: 100vw !important;
	height: 100%;
	top: 0;
	padding: 0;
	margin: 0;
	max-width: 100%;
	border-radius: 0 !important;
    background: transparent;

	.ant-modal-content{
		width: 100%;
		max-width: 100%;
		overflow: hidden;
		border-radius: 0;
		background: transparent;
	}

	.ant-modal-header{
		@apply hidden;
	}

	.ant-modal-body{
		@apply p-0 bg-transparent;
	}
}


.account-statement-modal-failed {
	@media (max-width: 767px) {
		width: 100vw !important;
		height: 100%;
		top: 0;
		padding: 0;
		margin: 0;
		max-width: 100%;
		border-radius: 0 !important;
		background: #fff;

		.ant-modal-content{
			width: 100%;
			max-width: 100%;
			overflow: hidden;
			border-radius: 0;
			background: #fff;
			height: 100%;
		}

		.ant-modal-header{
			@apply hidden;
		}

		.ant-modal-body{
			@apply p-0 bg-white h-full;
		}
	}
}

.bottom-sheet-on-sm {
	@media (max-width: 767px) {
		position: absolute;
		margin: 0;
		width: 100%;
		max-width: 100%;
		padding: 0;
		top: unset;
		bottom: 0;
	}
}

.full-width-mobile {
	.ant-modal {
		@media (max-width: 767px) {
			max-width: 100%;
		}
	}
}